import React, { useState } from 'react'
import { UncontrolledAlert } from 'reactstrap'
import { FaSync as Refresh } from 'react-icons/fa'
import { FiCheckCircle as Check } from 'react-icons/fi'
import { ActionButton } from './ActionButton'
import { useStores } from '../stores/RootStore'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

const RESEND_INVITE = gql`
  mutation resendUserInvite($userId: ID!) {
    resendInvite(userId: $userId)
  }
`

const humanizeError = e => {
  const errorMessage = e?.message
  if (errorMessage?.includes('status is not FORCE_CHANGE_PASSWORD')) {
    return 'The invite could not be sent because the user already exists and has been confirmed. If the user is unable to log in, they will need to reset their password.'
  }

  return errorMessage
}

export const ResendEmailInvite = ({ user }) => {
  // userId is the id from who will receive again the invite
  const { id: userId } = user || {}
  const { stores: { auth } } = useStores()
  const { user: { organizationId } } = auth

  const [successSent, setSuccessSent] = useState(false)
  const [resendInvite, { loading, error }] = useMutation(
    RESEND_INVITE,
    { onCompleted: () => setSuccessSent(true) }
  )

  if (auth.permissions.hasScopeInOrganization('organization:users:write', organizationId)) {
    return (
      <>
        <ActionButton
          className='mr-1'
          color='secondary'
          loading={loading}
          disabled={successSent}
          icon={successSent ? <Check /> : <Refresh size='12' />}
          onClick={() => resendInvite({ variables: { userId } })}
        >
          {successSent ? 'Invitation sent' : 'Resend Invitation'}
        </ActionButton>
        {error && (
          <UncontrolledAlert color='danger'>
            {humanizeError(error)}
          </UncontrolledAlert>
        )}
      </>
    )
  } else {
    return null
  }
}
