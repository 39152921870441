import React from 'react'
import { Badge } from 'reactstrap'
import { Link } from 'react-router-dom'
import { CoverTabs, UserAvatar, SudoButton, ResendEmailInvite, ViewLogs } from './'
import { ChangeUserStatusButton } from './ChangeUserStatusButton'
import { Permissions, platformRoles, organizationRoles } from 'suredone-common'
import { useQuery } from '@apollo/react-hooks'
import { FaBuilding } from 'react-icons/fa'
import { useStores } from '../stores/RootStore'
import gql from 'graphql-tag'
import { CompleteRegistration } from './CompleteRegistration'
import './UserCover.scss'

const GET_ORGANIZATION_DETAILS = gql`
  query OrganizationDetailsUserCover($id: ID!) {
    OrganizationDetails(id: $id) {
      ... on Organization {
        id
        name
        userId
        createDate
      }
    }
  }
`

function PlatformRoles ({ permissions }) {
  return (
    <div className='m-1'>
      {platformRoles
        .filter(role => permissions.hasRole(role.name))
        .map(role => <Badge key={role.name} color='info' className='mx-1'>{role.name}</Badge>)}
    </div>
  )
}

function OrgRoles ({ permissions }) {
  return (
    <span>
      {organizationRoles
        .filter(role => permissions.hasRole(role.name))
        .map(role => role.name)
        .join(', ')}
    </span>
  )
}

/**
 * Looper cover element for users (https://uselooper.com/user-profile.html)
 *
 * Usage:
 *  <UserCover user={user} />
 */
export function UserCover ({ user, availableTabs, back }) {
  const permissions = new Permissions(user.organizationId, user.roles, user.scopes)
  const { data } = useQuery(GET_ORGANIZATION_DETAILS, {
    variables: { id: user.organizationId }
  })
  const { OrganizationDetails } = data || { OrganizationDetails: {} }
  const { name: organizationName, userId, createDate } = OrganizationDetails

  const { stores } = useStores()
  const { auth } = stores || {}

  const isRegistrationIncomplete = auth.permissions.hasScope('platform:allUsers:finish-registration') && (!userId || !createDate)

  const getProperLink = () => {
    const content = <b><FaBuilding style={{ margin: '0 3px 4px' }} />{organizationName || 'Organization'}</b>
    if (auth.permissions.hasRole('PlatformSupport')) {
      return <Link to={`/admin/organizations/${user.organizationId}/profile`}>{content}</Link>
    } else if (auth.permissions.hasRole('Admin')) {
      return <Link to='/settings/organization/profile'>{content}</Link>
    } else {
      return organizationName
    }
  }
  return (
    <>
      <header className='page-cover'>
        <div className='cover-controls cover-controls-bottom'>
          <ViewLogs organizationId={user?.organizationId} />
          <ResendEmailInvite user={user} />
          <SudoButton userId={user.id} />
          <ChangeUserStatusButton user={user} />
          {isRegistrationIncomplete && <CompleteRegistration user={user} />}
        </div>
        <div className='text-center'>
          <UserAvatar size='5rem' user={user} />
          <h2 className='h4 mt-2 mb-0'> {user.fullName()} </h2>
          {user.enabled === false ? <Badge color='danger' className='ml-2'>Suspended user</Badge> : null}
          <PlatformRoles permissions={permissions} />
          <p className='text-muted'>
            {user.primaryEmail && <span> {user.primaryEmail} <br /> </span>}
            <OrgRoles permissions={permissions} /> {organizationName && <>at {getProperLink()}</>}
          </p>
          {user.bio && <p> {user.bio} </p>}
        </div>
      </header>
      <CoverTabs availableTabs={availableTabs} />
    </>
  )
}
