import React from 'react'
import { CardSubtitle, Col, Row } from 'reactstrap'
import Highlighter from 'react-highlight-words'
import { UserAvatar } from '../../components'
import './UserSummary2.css'
import Debug from 'debug'

const debug = Debug('sd:UserSummary2')

const UserSummary2 = ({ user, searchTerms, isListItem = false, to, logoUrl }) => {
  const { username, primaryEmail, legacyUserId, legacyAccountId, legacyAccountName } = user

  return (
    <Row className='align-items-center'>
      <Col className='col-auto'>
        <UserAvatar size={48} user={user} />
      </Col>
      <Col className='col-sm col-8 pr-0 pr-sm-12'>
        <h3 className='card-title'>
          <Highlighter
            className=''
            highlightTag='mark'
            autoEscape
            searchWords={searchTerms || []}
            textToHighlight={`${user?.firstName} ${user?.lastName}` || ''}
          />
          {username && <small className='text-muted'>@{username}</small>}
        </h3>
        <CardSubtitle className='text-muted d-flex align-items-center'>
          <Highlighter
            className='font-weight-light d-inline-block flex-grow-0 text-muted text-truncate'
            tag='h5'
            highlightTag='mark'
            autoEscape
            searchWords={searchTerms || []}
            textToHighlight={primaryEmail || ''}
          />
        </CardSubtitle>
        <span className='text-muted'> uid </span>
        &nbsp;
        <Highlighter
          highlightTag='mark'
          autoEscape
          searchWords={searchTerms || []}
          textToHighlight={legacyUserId || ''}
        />
        &nbsp;
        &nbsp;
        <span className='text-muted'> acct</span>&nbsp; <Highlighter highlightTag='mark' autoEscape searchWords={searchTerms || []} textToHighlight={legacyAccountName || ''} />
        <span className='text-muted'>
          &nbsp;(<Highlighter highlightTag='mark' autoEscape searchWords={searchTerms || []} textToHighlight={legacyAccountId || ''} />)
        </span>
      </Col>
    </Row>
  )
}

export { UserSummary2 }

debug('loaded')
