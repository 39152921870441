import React from 'react'

const news = [
  {
    id: 'v3.319',
    date: 'October 4th and 11th, 2024',
    title: 'Amazon/eBay/BigCommerce/Shopify/Miva/Fitment/Automations Updates and Fixes + WPS Fitment Import',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li><b>SureDone versions now span 2 weeks.</b></li>
        <li>Shopify: upgrade to API version <code>2024-01</code> which includes the start of migration from <code>shopifyimageids</code> to <code>shopifymediaids</code>.</li>
        <li>Shopify: introduce support for <code>shopifymediareset</code>.</li>
        <li>Fitment SureFit: introduce support for WPS vehicle Ids via <code>wpsvehicleids</code>.</li>
        <li>Western Power Sports (WPS): introduce Fitment Import integration.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.319-wps-fitment-import.png' alt='WPS Fitment Import' />
        </li>
        <li>PartsUnlimited: introduce Inventory Update API integration to replace the Inventory Update integration.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.319-partsunlimited-inventory-api-update.png' alt='PartsUnlimited Inventory Update API integration' />
        </li>
        <li>Automations: introduced Orders Mark Shipped integration to clear older orders from the awaiting shipments view.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.319-orders-mark-shipped-integration.png' alt='Orders Mark Shipped integration' />
        </li>
        <li>Shopify: introduce display/return of API errors on image upload failures.</li>
        <li>Amazon Reports UI: introduce setting to enable/disable report generation.</li>
        <li>Amazon: improve logging of <code>status queued</code> to show correct <code>operation</code> and data enqueued.</li>
        <li>Amazon: listing report job now sets <code>amznislisted 0</code> for listings not on the all listings report.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Shopify/BigCommerce: fix issue with throttled updates not being sent when fields changes across products are exactly the same.</li>
        <li>Miva: fix various issues with products/variations not updating.</li>
        <li>Fitment SureFit: fix issue with storefront search results not showing all results correctly.</li>
        <li>eBay: fix issue with <code>ebayskip</code> logs not showing correctly sent value.</li>
        <li>Amazon: fix issue with <code>amznskip</code> logs not showing correctly sent value.</li>
        <li>Amazon: fix issue with unsetting <code>amznislisted</code> with <code>action end</code>.</li>
        <li>BigCommerce: remove <code>order_source www</code> restriction when setting the Default Channel to only import orders with empty or matching <code>channel_id</code>.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.318',
    date: 'September 27th, 2024',
    title: 'BigCommerce/ShipStation Upgrades + Magento2 Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>BigCommerce: introduce support for description <code>bigcommerce-product.htm</code> via advanced setting.</li>
        <li>Automations: introduce setting for choosing eBay order number formats in <tt>ShipStation Order ID Import</tt> and <tt>ShipStation Order Shipment Import</tt> API integrations.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.318-shipstation-ebay-order-numbers.png' alt='ShipStation eBay Order Numbers Setting' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Magento2: fix issue with categories not being removed from the storefront.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.317',
    date: 'September 20th, 2024',
    title: 'Amazon/Shopify/Automation Engine Upgrades + Turn14 Fitment Import',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Amazon: introduce orders import process redundancy.</li>
        <li>Shopify: introduce internal global throttle support to reduce <code>429</code> errors.</li>
        <li>Automation Engine: improve retry logic for <code>FTPS</code> downloads.</li>
        <li>Automation Engine: introduce config for <code>max_concurrency</code> to limit the number of parallel runs across the infrastructure for less scalable integration connections.</li>
        <li>Turn14: drop ship update to combine multiple line items with duplicate <code>turn14id</code> into one request, as in the case with potentially kit exploded orders.</li>
        <li>Turn14: introduce integration for Fitment Import.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.317-turn14-fitment-import.png' alt='Turn14 Fitment Import' />
        </li>
        <li>Meyer Distributing: Cost Import support <code>meyeroversize meyeraddtlhandling</code>.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Magento2: fix issue with <code>description price</code> etc attributes not sending on update.</li>
        <li>Storefront: fix issue with checkout Authorize.net.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.316',
    date: 'September 13th, 2024',
    title: 'Amazon/eBay/Walmart/Google/Shopify/Reports/Errors UI Updates and Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Walmart: ship tracking process <code>shipservice LTL</code> as <code>methodCode FREIGHT</code>.</li>
        <li>Google: send boolean <code>true false</code> for fields <code>identifierExists adult isBundle</code>.</li>
        <li>Channel Errors UI: rephrase <tt>X days ago</tt>.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.316-channel-errors-x-days-ago.png' alt='Channel Errors X Days Ago' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Amazon: fix issues with imported orders delayed or missing.</li>
        <li>eBay: fix issue with error <code>21920416</code> by omitting <code>Title Description ItemSpecifics Pictures</code> when returned in the error message.</li>
        <li>Shopify: fix issue with incorrect stock value on variants when creating a new listing.</li>
        <li>Reports: fix issue with <tt>Unsold SKUs</tt> returning sold items within specified date range.</li>
        <li>Bulk Exports: fixed issue with saved order exports not saving <tt>Line Item</tt> option.</li>
        <li>Channels: fix issue with <code>skipupdate</code> not working for channel fields such as <tt>shopifyprice</tt>.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.315',
    date: 'September 6th, 2024',
    title: 'Fitment SureFit ACES Vehicle IDs Support + Automation Engine/BigCommerce Upgrades and Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>BigCommerce: when setting the <tt>Default Channel</tt> only import orders with empty or matching <code>channel_id</code> with <code>order_source www</code>.</li>
        <li>Fitment SureFit: introduce support for ACES vehicle Ids via <code>acesvehicleids</code>.</li>
        <li>Automation Engine: introduce <code>connection.throttle</code> support to handle multi threaded retries against APIs with conservative limits.</li>
        <li>Automation Engine: improve HTTP <code>Retry-Time</code> handling.</li>
        <li>Automation Engine: in using <code>vendor_actions.combine</code> contains a single array field, try imploding based on delimiter.</li>
        <li>Automations: introduce Steeleng Inventory Update integration.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Users: fix issue with <tt>Resend Invitiation</tt> for new users that receive a <code>FORCE_CHANGE_PASSWORD</code> error.</li>
        <li>BigCommerce: fix issue with order tracking failing when the <tt>product resource</tt> is missing or invalid.</li>
        <li>Automation Engine: fix issue with <code>connection.cache</code> enabled with running multiple processes while the cache generating execution is still running.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.314',
    date: 'August 30th, 2024',
    title: 'Bulk/Orders + Shopify/Magento2 Upgrades and Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Magento2: improve performance of throttled retry jobs.</li>
        <li>Shopify: require <code>force</code> for protected fields <code>productid variantid inventoryitemid</code>.</li>
        <li>Bulk: results that include the message <code>Bulk Request Throttled Retry queued</code> will now include the deduplicated <code>job_id</code>.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.314-bulk-results-retry-message.png' alt='Bulk Request Throttled Retry queued ID' />
        </li>
        <li>Channel Errors UI: now includes logs with <code>result failure</code> to the reporting.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.314-channel-errors-failure.png' alt='Channel Errors Failures' />
        </li>
        <li>Orders UI: introduce modal with confirmation for combine orders functionality.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.314-orders-ui-combined.png' alt='Orders UI Combined Modal' />
        </li>
        <li>Orders: <code>lineitem_filter</code> is now supported on non-line item requests to filter out specific order items.</li>
        <li>ShipStation: introduce support for <code>lineitem_filter</code> query to filter specific order items.</li>
        <li>Turn14: Catalog import dimensions for the 1st package only.</li>
        <li>WPS: Catalog Import now includes <code>wpsmap wpsproducttype longdescription</code>.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Storefront: fix issue with sort not working.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.313',
    date: 'August 23rd, 2024',
    title: 'Shopify/Fitment and Automations Upgrades and Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Shopify/BigCommerce/Magento2: improve retry and kit job process handling.</li>
        <li>Shopify: orders import check 2 hours prior from last sync time.</li>
        <li>Shipping Carrier API: reset FedEx/UPS accept <code>eula</code> setting on failed carrier authorization.</li>
        <li>Fitment SureFit: introduce support for <code>action end channel.skip</code> values.</li>
        <li>Automations Drop Shipping: update  drop shipping shippingservice mapping to set UPS 2nd Day from <code>shippingservice: ShippingMethodExpress</code> for Turn14, Motorstate, Tucker, WPS, Premier Performance and Meyer Distributing. Set FedEx Express services for Automatic Distributors, Keystone and NTP.</li>
        <li>Automations: update eBay auto renew and expire listings integrations to run 3 hours apart.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Turn14: fix issue with <code>shipping service mapping</code> failing when requested service is not available and will now fallback to default shipping selection.</li>
        <li>Automations UI: fix issue with errors not returning descriptive messages.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.313-automations-ui-error-messages.png' alt='Automation UI Error Messages' />
        </li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.312',
    date: 'August 16th, 2024',
    title: 'Channels/Automations Interface and Automation Engine/Fitment Upgrades and Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>SkuVault: order export omit <code>archived</code> orders.</li>
        <li>Automations UI: introduce support for searching by installed <code>id</code>.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.312-automations-ui-installed-id.png' alt='Automations UI Installed ID' />
        </li>
        <li>Channels UI: introduce support for deleting channel integrations.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.312-channel-delete-ui.png' alt='Channel Delete Interface' />
        </li>
        <li>Automation Engine: introduce <code>fitment_export_catepid</code> to support exporting fitment from <tt>Catalog EPIDs</tt>.</li>
        <li>Fitment Export Automation: include exporting of fitment via Catalog EPID.</li>
        <li>DX1: Inventory Update compare ignore updates where <code>datesold</code> is later than DX1 <code>DateUpdated</code>.</li>
        <li>Automations: introduce configurable <code>identifier</code> and settings for product creation and updates for the following <tt>Catalog Import</tt> integrations: Automatic Distributors, Keystone, Motorstate, Torqued Distribution, Turn14, WPS, Horizon Hobby, Brock Supply, V-Twin and Holley.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Bulk Orders: fix issue with updates to <code>email</code> not saving.</li>
        <li>Miva: fix issue with default processing of <code>longdescription</code> for <code>mivadescription</code>.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.311',
    date: 'August 9th, 2024',
    title: 'Amazon/Channel Skips/Walmart/Shopify/BigCommerceFitment Upgrades and Fixes + Klaviyo Integrations',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Amazon: imports now include data for <code>amznstatus</code>.</li>
        <li>Amazon: update <code>amznstatus</code> changes from periodic listing reports job.</li>
        <li>Amazon: no longer set lowercase <code>amznstatus</code>, will now set one of <code>Active, Incomplete, Inactive, Out of Stock, Suppressed, Incomplete</code>.</li>
        <li>Amazon: no more sending <code>FulfillmentLatency</code> with FBA showing enabled within the product.</li>
        <li>Amazon: introduce setting <code>sync_fba_status</code> to update <code>amznfba.status</code> changes from periodic listing reports job.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.311-amazon-sync_fba_status.png' alt='Amazon Sync FBA Status' />
        </li>
        <li>Channels: improve <code>channel.skip</code> processing.</li>
        <li>Product Editor: remove channel skips <tt>Send Once</tt> label, no change to underlying functionality.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.311-product-editor-channel-skips.png' alt='Product Editor Channel Skips' />
        </li>
        <li>Walmart WFS: import orders with <code>shippingstatus COMPLETE</code>.</li>
        <li>Shopify: set <code>status ARCHIVED</code> from <code>action end</code> requests.</li>
        <li>Orders: introduce support for <code>shipsku</code> matching to <code>order.items.sku</code> via <code>shipskufield</code> and <code>shipskuvalue</code> where <code>shipskufield</code> may be a product field existing in <code>order.items.itemdetails.product.shipskufield</code> and <code>shipskuvalue</code> matching to that product field value. Works with inserting shipments into existing orders.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.311-shipments-shipskufield-shipskuvalue-bulk-csv.png' alt='Orders shipsku shipskufield shipskuvalue bulk csv' />
        </li>
        <li>Fitment SureFit: support action <code>end</code> to remove data from fitment channels.</li>
        <li>Turn14: fix issue with drop shipping <code>shippingservice mapping</code> not mapping to requested shipping services.</li>
        <li>WPS: Catalog import now includes mapping for <code>manufacturerpartnumber/mpn</code> from <code>supplier_product_id</code>.</li>
        <li>Klaviyo: introduce integrations for Orders Create/Update Profiles and Events for Placed Order and Fulfilled Order.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.311-klaviyo-integrations.png' alt='Klaviyo Integrations' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>BigCommerce: fix issue with ship tracking failures due to BigCommerce API failures.</li>
        <li>Channels API: fix issue with results including integrations that are not installed.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.310',
    date: 'August 2nd, 2024',
    title: 'Amazon/Fitment/Logs + Channels Upgrades and Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Amazon: introduce setting <tt>Allow FulfillmentLatency of 0 Days</tt> to enable sending <code>FulfillmentLatency 0</code>.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.310-amazon-fulfillment-latency-0.png' alt='Amazon FulfillmentLatency 0' />
        </li>
        <li>Logs UI: introduce search experience enhancements.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.310-logs-search-ux-update.png' alt='Logs Search UX Update' />
        </li>
        <li>Bulk Fitment: append <code>fitmenttype MML</code> for converted powersports fitment files.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.310-bulk-fitment-powersports-fitmenttype.png' alt='Bulk Fitment fitmenttype mml' />
        </li>
        <li>Channel Imports: improve logging for <code>retry</code> including Shopify request job ids.</li>
        <li>Channels API: introduce support to delete main channel integration if there are no existing channel instances. Supported for all channels except eBay.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>eBay: fix issue with UI category search in sending default <code>siteId</code> for <code>getCategorySuggestions</code>.</li>
        <li>Shopify: fix issue with logs showing <code>result failure</code> for meta fields that are not updated via <code>action relist</code>.</li>
        <li>Channels: fix issue with creation of Walmart, BigCommerce, Shopify, Google, Facebook/Instagram, Magento2 and Miva integrations setting default sku length at 50 characters. You may submit a support ticket to update your existing channel integrations to support a length of 100 characters.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.309',
    date: 'July 26th, 2024',
    title: 'eBay/BigCommerce/Shopify/UI/Automations Upgrades and Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>eBay: remove static restricted product list for CARB Approve Tuner List products.</li>
        <li>Channels Field Mapping UI: introduce <tt>red x</tt> to unset mapping values.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.309-channel-field-mapping-unset.png' alt='Channel field mapping unset' />
        </li>
        <li>BigCommerce: add connection <code>logs.request</code> and <code>logs.response</code> for orders ship tracking.</li>
        <li>Shopify: settings interface introduce <tt>Download Collections</tt> function to export store collections and IDs to a CSV file.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.309-shopify-download-collections.png' alt='Shopify Download Collections CSV' />
        </li>
        <li>Product Editor UI: introduce support for using fitment selector without enabling an eBay integration.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.309-show-fitment-ui.png' alt='Show Fitment UI' />
        </li>
        <li>Automatic Distributors: introduce cost import integration.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.309-automatic-distributors-cost-import.png' alt='Automatic Distributors Cost Import integration' />
        </li>
        <li>Automations: update <tt>Ship Service Mapping</tt> to be channel agnostic and operate by checking <code>shippingservice</code>. Affected drop shipping integrations for: Automatic Distributors, Keystone, Meyer, Motorstate, NTP, PartsUnlimited, Premier Performance, Tucker, Turn14 and WPS.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Turn14: fix issue with PO requests sending incorrect shipping country post <a href='https://t14api.releasenotes.io/release/xgONB-api-update-rollup-july-2022' target='_blank' rel='noopener noreferrer'>API update</a>.</li>
        <li>Products/Inventory Settings: fix issue with <tt>Listings - Default Condition</tt> page errors.</li>
        <li>BigCommerce: Fix issue with fallback to product price not working when submitting empty <code>bigcommerceprice</code>.</li>
        <li>BigCommerce: fix issue with brand selector not working in the product editor.</li>
        <li>BigCommerce: fix issue with <code>bigcommerceavailability</code> not showing the correct default value in the product editor.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.309-bigcommerce-availability-toggle-default.png' alt='BigCommerce Availability toggle default' />
        </li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.308',
    date: 'July 19th, 2024',
    title: 'Amazon/eBay/Walmart Upgrades and Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>eBay: no longer send <code>CompleteSale.ShipmentTrackingDetails</code> when sending <code>shipcarrier</code> with no <code>shiptracking</code>.</li>
        <li>eBay: per feedback, migrated the multi item orders combined note to <code>details.combinedOrderNumbers</code>.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.308-ebay-combined-orders.png' alt='eBay combined order notes' />
        </li>
        <li>Amazon Reports UI: show last 10 completed buy box reports.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.308-buy-box-report-10.png' alt='Amazon Buy Box Reports 10' />
        </li>
        <li>Walmart WFS: import orders as shipped and with available shipment information.</li>
        <li>Automations: DX1 Inventory Update refactored to leverage latest 20240715 API updates.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Walmart: fix issue with <code>swatchImages</code> being sent incorrectly.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.307',
    date: 'July 12th, 2024',
    title: 'Amazon/Shopify/BigCommerce/Magento2 + Automation Upgrades and Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Shopify/BigCommerce/Magento2: introduce order import shipping carrier and service mapping.</li>
        <li>BigCommerce: orders import check 2 hours prior from last sync from from 30 mins.</li>
        <li>Ordoro: introduce integrations for Catalog Export, Inventory Update and Orders Export and Shipment Import.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.307-ordoro-integrations.png' alt='Ordoro Integrations' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Amazon: fix issue with requests not respecting Send Only ASIN.</li>
        <li>Magento2: fix issue with categories import not running.</li>
        <li>Automation Engine: fix issue with <code>linked_parameters</code> not respecting set but empty values such as <code>0</code>.</li>
        <li>Automation Engine: fix issue with SFTP integrations not retrying when processing multiple files.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.306',
    date: 'July 5th, 2024',
    title: 'eBay, Amazon, Walmart + Shopify Upgrades and Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>eBay: multi item orders now include <code>internalnotes</code> of which single orders are combined.
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.306-ebay-combined-orders-internalnotes.png' alt='eBay Combined Orders Internal Notes' />
        </li>
        <li>Walmart: orders import now includes WFS and 3PL orders.</li>
        <li>Shopify: imports now include meta field values.</li>
        <li>Shopify and BigCommerce Settings UI: show message for which settings are being imported.</li>
        <li>Automation Engine: match bulk result headers for actions <code>end delete</code>.</li>
        <li>Channel Errors UI: improve page load and fix crashes via new pagination.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.306-channel-errors-ui.png' alt='Channel Errors UI Pagination' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>eBay: fix issue with auth expires url directing to legacy interface.</li>
        <li>Amazon: fix issue with setting <code>amzn.instance_sku</code> erroneously on actions <code>add relist</code> when a previous instance is not skipped.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.305',
    date: 'June 28th, 2024',
    title: 'UI, BigCommerce and Fitment SureFit Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Product editor UI: display of <code>total_stock</code> in summary section.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.305-total-stock.png' alt='Total Stock' />
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.305-total-stock-kits.png' alt='Total Stock Kits' />
        </li>
        <li>Fitment SureFit: YMM search results now default sort by product <code>id</code> descend.</li>
        <li>Fitment SureFit: introduce <code>apply-other-by-default</code> to apply <tt>other</tt> filters by default.</li>
        <li>BigCommerce: introduce field mapping support for <code>minstock maxstock</code>.</li>
        <li>Motorstate: force <code>ISO-8859-1</code> encoding for drop shipping payloads.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Fitment SureFit: fix issue with <tt>Other</tt> filter showing when it is the only option to filter by.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.304',
    date: 'June 21st, 2024',
    title: 'Amazon, Shopify Upgrades + New Miva Integration',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Kits: logging introduced for enqueued kit update requests with potential deduplicated <code>job_id</code>.</li>
        <li>Automation Engine: introduce configs for <code>cached</code> and <code>cached_ttl</code> to support reducing HTTP API requests across integrations using the same endpoint with shared data.</li>
        <li>Shopify: Update API version <code>2023-10</code>.</li>
        <li>Amazon: introduce <code>logs.field_data</code> in preparing xml feed payloads.</li>
        <li>eBay: migrate <code>GetSuggestedCategories</code> to <code>getCategorySuggestions</code> for category lookups.</li>
        <li>Keystone: inventory update add support for temporary warehouse <code>TexasDFWQty</code>.</li>
        <li>Turn14: All requests now include the header <code>User-Agent: SureDone/3.0</code>.</li>
        <li>Turn14: introduce automated <code>search</code> limited to <code>shipping.country US</code>.</li>
        <li>Turn14: introduce Catalog Import support for identifiers by <code>turn14sku turn14id</code>.</li>
        <li>Automations Distributor Drop Shipping: with <code>shipping service mapping</code> enabled, support generic mapping for <code>shipservice standard</code> and <code>shipservice expedited</code> for the following: Turn14, Tucker, Motorstate, Meyer Distributing, Keystone, NTP, Parts Unlimited, Western Power Sports, GSP, Premier Performance, Parts Authority and Automatic Distributors.</li>
        <li>Automations: update eBay auto renew and expire listings integrations to support integration instances.</li>
        <li>Automations: introduce eBay category import search integration, leverages the ebay <code>getCategorySuggestions</code> API.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.304-ebay-get-category-suggestions-import.png' alt='eBay category suggestions import' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Logs: fix issue with missing logging on integration deletion.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.303',
    date: 'June 14th, 2024',
    title: 'Amazon, Shopify Upgrades + New Miva Integration',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Amazon: Send Only ASIN now prevents image and variation relationship feeds.</li>
        <li>Amazon Buy Box Report: Polish report headers changing <tt>boolean</tt> values to <code>yes/no</code>.</li>
        <li>Miva: introduce new integration for full product/listing, inventory, price, order and fulfillment management.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.306-miva-integration.png' alt='WebShopManager Ship Tracking Integration' />
        </li>
        <li>Shopify: introduce support for importing, managing and updating <code>meta_fields</code> via field mapping.</li>
        <li>Shopify: introduce support for variant meta fields.</li>
        <li>WebShopManager: update order import mapping for billing and shipping addresses.</li>
        <li>WebShopManager: introduce integration for order ship tracking fulfillment.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.306-webshopmanager-ship-tracking.png' alt='WebShopManager Ship Tracking Integration' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Bulk Exports: fix issue with permissions not working as expected.</li>
        <li>Automations UI: fix issues from introducing searching by shared integration id.</li>
        <li>Shopify: fix issue with logging <code>identifier</code> as the channel sku instead of the <code>guid</code>.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.302',
    date: 'June 7th, 2024',
    title: 'Automation upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>eBay: import logging improvements.</li>
        <li>Turn14: catalog import now includes <code>turn14brandid brandcode</code>.</li>
        <li>PartsUnlimited: drop shipping now includes channel data for eBay, Amazon and Walmart orders.</li>
        <li>LightspeedPOS: introduce inventory and catalog export integrations.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.302-lightspeed-catalog-inventory-export.png' alt='Lightspeed Catalog and Inventory Export' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Packing Slips: fix issue with <tt>search</tt> not applying advanced invoice features.</li>
        <li>Amazon: fix legacy issue that calculated <code>price</code> including <code>handlingfee</code>.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.301',
    date: 'May 31st, 2024',
    title: 'Channels/Logs/Fitment Upgrades + Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Walmart: improve logging of the <code>syncStatuses</code> operation.</li>
        <li>Amazon Settings: expose <tt>send only asin</tt> feature.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.301-send-only-asin.png' alt='Amazon Send Only ASIN' />
        </li>
        <li>Logs UI: introduce date search by time and ability to edit filters.</li>
        <li>Fitment: logs normalized and accounting for request and skip flags.</li>
        <li>Fitment SureFit: fix issue with facets not removing from products.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Walmart: fix issue with imports not completing.</li>
        <li>Logs UI: fix auto-populating fields.</li>
        <li>Logs UI: fix issue with leading and trailing spaces in field values.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.300',
    date: 'May 24th, 2024',
    title: 'Channels/Logs/Fitment Upgrades + Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Magento2: send only updated fields on action <code>edit</code>.</li>
        <li>Automations UI: enable search by unique id for shared integrations.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.300-automation-ui-search.png' alt='Automations search' />
        </li>
        <li>Fitment SureFit: my garage tag introduced.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.300-surefit-fitment-my-garage.png' alt='Fitment my garage tag' />
        </li>
        <li>Logs: ability to perform secondary on user <code>accountId</code>.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Shopify: fix issue with failing reset of <code>shopifyprice</code>.</li>
        <li>Walmart: imports fix variation <code>sku</code> grouping by using <code>variantgroupid</code>.</li>
        <li>Logs: fix issue with searching fields not returning results for data in the log.</li>
        <li>Imports UI: fix issue with <code>skipupdate</code> fields not working.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.299',
    date: 'May 17th, 2024',
    title: 'Fitment + Automation Engine upgrades and fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Fitment SureFit: introduce support for multi facetted query results.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.299-surefit-fitment-multi-facetted-query-results.png' alt='SureFit mult facetted query results' />
        </li>
        <li>Settings: product variation, stock and kit fields can now be re-ordered by dragging.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.299-settings-variation-stock-kit-fields.png' alt='UI Settings variation stock kit fields' />
        </li>
        <li>Shopify: introduce support for adding new variants to existing product listings.</li>
        <li>Automations UI: enable search by unique id for shared integrations.</li>
        <li>Automation Engine: introduce <code>fitment_export_duplicates</code> to restrict exporting duplicate product fitment entries if present, defaults to true.</li>
        <li>Automation Engine: increase retries for SFTP integrations when processing many large files.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>ShipStation: fix issue with orders not exporting that met generic Amazon MCF criteria with Amazon auto fulfillment enabled.</li>
        <li>Automation Engine: fix issue with diff updates not working that rely on combined identifiers.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.298',
    date: 'May 10th, 2024',
    title: 'Channels: core upgrades and fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Channels: introduce job infrastructure upgrades that reduce or removes backup of large catalog update processing.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Channels: fix issue with default <code>channel.skip</code> values not being respected on actions <code>start, add</code>. Affects Shopify, BigCommerce, Magento/2, Google, Facebook/Instagram, Etsy, Walmart and Amazon.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.297',
    date: 'May 3rd, 2024',
    title: 'eBay Auto Renew/Expire Automations + Automation/Fitment Upgrades and Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Automations: introduce eBay auto renew and expire listings integrations.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.297-ebay-auto-renew-expire-automation.png' alt='eBay Auto Renew Expire Integrations' />
        </li>
        <li>Logs: re-include large api transmission keys from channel requests.</li>
        <li>Fitment: fitment table now exposed in <code>window.sdFitmentData</code>.</li>
        <li>Fitment: rewrite values that are non-UTF8 encoded.</li>
        <li>Automation EDI: 856 support shipservice via TD503.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>eBay: fix issue with imports matching to non-variations.</li>
        <li>Automation Engine: SFTP fix issue with failed updates due to processing many large files causing connection timeout.</li>
        <li>Turn14: fix issue with <tt>Preferred Access</tt> failures with split shipments.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.296',
    date: 'April 26th, 2024',
    title: 'Amazon Buy Box Report + Channel/Automation Engine/Fitment Upgrades and Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Amazon: introduce Buy Box report.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.296-amazon-buy-box-report.png' alt='Amazon Buy Box Report' />
        </li>
        <li>eBay: imports now retry if listing id report is incomplete.</li>
        <li>Walmart: add ship tracking mapping for USPS <code>PARCEL</code> to Standard shipping.</li>
        <li>Walmart: improve logging for listings not synced.</li>
        <li>Automation Engine: introduce config <code>fitment_export_empty_fill</code> object with fitment fields to corresponding fill values for when export field value is empty.</li>
        <li>Fitment: remove products from YMM when sending in empty fitment data or updating <code>fitmenttype</code> from <code>UNIVERSAL</code>.</li>
        <li>WebShopManager: fitment export empty <code>TRIM</code> with <code>All</code>.</li>
        <li>Storefront: introduce template function <code>sd_makeslug</code>.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>eBay: fix issue with failing bulk requests.</li>
        <li>Walmart: fix issue with failed fulfillments missing <code>intentToCancelOverride true</code>.</li>
        <li>Walmart: fix issue with product imports and listing reports failing and/or being delayed.</li>
        <li>Walmart: fix issue with ship tracking not sending when shipments include <code>shipsku</code> that does not match the order item sku and there is only one shipment.</li>
        <li>Bulk Fitment: fix issue with failed processing of files with over 1 million lines by splitting requests.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.295',
    date: 'April 19th, 2024',
    title: 'Walmart Upgrades + eBay and Bulk Fitment Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Walmart: introduce setting for <code>fulfillment_lag_time_exception</code> to allow overrides for lag times greater than <code>0</code> and <code>1</code>.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.295-walmart-lagtime-override.png' alt='Walmart Lagtime Override' />
        </li>
        <li>WebShopManager: deprecate <code>Catalog Export</code> API integration in favor of new Catalog SFTP integrations.</li>
        <li>Horizon Hobby: upgrade catalog import with mapping for <code>brand, manufacturerpartnumber, media</code>.</li>
        <li>Bulk Fitment: rewrite converted file to include error when there are no matches.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.295-bulk-fitment-empty-rows.png' alt='Bulk fitment no matches' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>eBay: fix issue with error <code>Cannot list with both product listing details and item compatibilities.</code> by checking if fitment data exists on the product and forcing <code>IncludeeBayProductDetails false</code>.</li>
        <li>Automation Engine: fix issue with email automations sending repeatedly when encountering an order missing an email.</li>
        <li>Bulk Fitment: fix issue with uploads that include missing headers.</li>
        <li>Bulk Fitment: fix issue with failures due to files with empty rows.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.294',
    date: 'April 12th, 2024',
    title: 'eBay + Shopify + Automations Fixes and Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>LightspeedPOS: Inventory integration by Item Shop introduced.</li>
        <li>Keystone: Cost import includes more optional fields for mapping.</li>
        <li>Brock Supply: introduce order import <code>CANCELED</code> status.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Shopify: fix issue with updates requested not updating on Shopify when same as what is already in database.</li>
        <li>eBay: fix issue with <code>ebayitemspecifics</code> payload not overwriting database and live listing <tt>Item Specifics</tt>.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.293',
    date: 'April 5th, 2024',
    title: 'Fitment Upgrades + Automations',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Core: enhance kit processing performance with deduplication.</li>
        <li>Fitment: upgrade instructions with usage for new advanced tags.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.293-fitment-documentation-tags.png' alt='Fitment Advanced Instructions' />
        </li>
        <li>Fitment Settings: display active custom facets.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.293-fitment-facets.png' alt='Fitment Facets' />
        </li>
        <li>DX1: introduce integrations for SKU and Inventory imports.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.293-dx1-integrations.png' alt='DX1 Integrations' />
        </li>
        <li>Keystone: drop shipping introduce default ship method, allows for empty value.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.293-keystone-ship-method-default.png' alt='Keystone Ship Method Default' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>BigCommerce UI: fix issue with <code>bigcommerceavailability</code> having incorrect default value set in toggle.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.292',
    date: 'March 29th, 2024',
    title: 'Automation Engine Fitment Upgrades and Fixes, New Parts Authority + Holley Integrations',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Etsy: Updated <code>when_made 2020_2024</code> value.</li>
        <li>eBay: import remove <code>content</code> field with legacy html table of converted fitment.</li>
        <li>Automation Engine: email compress attachments greater than 10mb.</li>
        <li>Automation Engine: introduce configs for <code>trigger_escape_characters</code> and <code>trigger_escape_keys</code> as respective arrays of characters to escape and keys the escaping may apply to.</li>
        <li>Automation Engine: Introduce <code>type: fitment</code> support for <code>action: export</code> to export year, make and model fitment data.</li>
        <li>Automation Engine: introduce support for <code>fitment_export_type</code> to support exports of both Auto Parts and Powersports fitment data exports.</li>
        <li>Automation: introduce new automation for emailing product fitment data export as attachment based on a search.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.292-fitment-export-email.png' alt='Fitment Export Integration' />
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.292-fitment-export-csv.png' alt='Fitment Export CSV' />
        </li>
        <li>Parts Authority: launched integrations for SKU, Inventory and Cost imports plus drop shipping.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.292-parts-authority.png' alt='Parts Authority Integrations' />
        </li>
        <li>Holley: launched integrations for SKU, Catalog Import and Inventory Update.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.292-holley-integrations.png' alt='Holley Integrations' />
        </li>
        <li>Turn14: Inventory Update now includes new Indianapolis warehouse code <code>03</code>.</li>
        <li>WebShopManager: introduce integration for fitment export.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Core: fix errors with image urls by trimming <code>media1 - media10, mediax</code> of additional whitespace.</li>
        <li>Bulk Fitment: fix issue with upload warning for missing recommended headers when they are not valid account fields.</li>
        <li>Bulk Fitment: fix issue with ignoring <code>engine</code> attributes when the engine field is present but empty.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.291',
    date: 'March 22nd, 2024',
    title: 'Automation Engine + UI Upgrades and Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Amazon MCF: set order <code>internalnotes: Sent to Amazon FBA via MCF</code> on success.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.291-amazon-mcf-internalnotes.png' alt='Amazon MCF internalnotes' />
        </li>
        <li>Search Builder UI: Improve listed fields and user experience.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.291-search-builder-updates.png' alt='Search Builder Improvements' />
        </li>
        <li>Shopify: introduce <code>price</code> data validation.</li>
        <li>Automation Engine: Introduce <code>connection.ssl_http</code> to allow for disable of verify peer, defaults <code>true</code>.</li>
        <li>Automation Engine: EDI support for enterprise account user and automation id overrides.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Orders UI: fix issue with Amazon MCF not showing on newer accounts.</li>
        <li>Reports: descriptions updated to clarify functionality for Inventory and Restock reports.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.290',
    date: 'March 15th, 2024',
    title: 'UI + Magento2/Automation Engine Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Product list editor: introduce warning during close when work is unsaved.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.290-bulk-edit-exit.png' alt='Bulk edit exit warning' />
        </li>
        <li>Magento2: introduce support for direct import and export of <code>options</code> as <code>magentotwocustomoptions</code>.</li>
        <li>Automation Engine: Introduce <code>validate_headers false</code> config to support generation of bulk files with all available integration fields.</li>
        <li>WPS: increase the number of images in the catalog import integration.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.289',
    date: 'March 8th, 2024',
    title: 'Amazon AutoMCF + Integration Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Amazon: upgrade Auto Multchannel Fulfillment MCF to support any active Amazon instance.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.289-amazon-automcf-settings.png' alt='Amazon AutoMCF Settings' />
        </li>
        <li>Amazon: introduce support for searches to limit orders processed by Auto MCF via the Amazon <code>orders_auto_fulfillment_search_rule</code> setting.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.289-amazon-automcf-search-rule.png' alt='Amazon AutoMCF Search Rule' />
        </li>
        <li>Walmart: introduce more logging of API request and response data.</li>
        <li>Skuvault: fix issue with missing <code>skuvaultlocationinventory</code> updates.</li>
        <li>WebShopManager: introduce new sku import integration that imports <code>StockID</code> as <code>wsmsku</code> that match to <code>guid</code>.</li>
        <li>Storefront: introduce support for <code>sd_checkstock()</code> for dynamic checks of product inventory.</li>
        <li>Automation Engine: improve <code>vendor_action.search</code> matching with zero date values.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.288',
    date: 'March 1st, 2024',
    title: 'Walmart + Fitment and UI Upgrades and Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Walmart: only send <code>fulfillmentLagTime</code> if value is 0 or 1.</li>
        <li>Walmart: import <code>walmartproductid</code> during periodic listing status syncing.</li>
        <li>Automation Engine: remove restriction for which headers may be set in <tt>bulk fitment</tt> requests.</li>
        <li>Fitment: introduce support for storing raw fitment formats and all extended data attributes. For example, store your ACES fitment data in text format for display in the SureFit module and for upcoming fitment management.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.288-fitment-table.png' alt='Fitment extended attributes table' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Orders UI: fix issue where orders settings controlled header navigation.</li>
        <li>Product Editor UI: fix issues with cursor <tt>jumping</tt> and elements not being selectable.</li>
        <li>Automation Engine: fix issue with <tt>fitment</tt> uploads skipping channel updates.</li>
        <li>eBay: fix issue with tracking not being sent with <code>options.orders_settings.sync_enabled false</code>.</li>
        <li>Amazon: fix issue with acknowledging orders with <code>options.orders_settings.sync_enabled false</code>.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.287',
    date: 'February 23rd, 2024',
    title: 'Amazon Upgrade + Amazon/BigCommerce/Automation Engine Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Amazon: introduce more logging for MCF <code>createFulfillmentOrder</code> requests.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Amazon: fix issue with FBA MCF orders not importing shipments.</li>
        <li>BigCommerce: fix issue with edge case errors with values in <code>bigcommercevideos</code>.</li>
        <li>BigCommerce: fix issue with <code>bigcommerceavailability</code> return errors in the product editor.</li>
        <li>Automation Engine: fix issue with jobs failing from error responses that are handled by the configuration.</li>
        <li>Turn14: fix issue with <tt>Preferred Access</tt> drop shipping error <code>The shipping_id provided was not valid</code>.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.286',
    date: 'February 16th, 2024',
    title: 'Walmart Upgrades + Shopify/Automation Engine Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Walmart: import <code>wpid</code> as <code>walmartproductid</code> and use it to link to marketplace listing.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.286-walmart-productid-link.png' alt='Walmart Product ID link' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Shopify: fix issue with sending variation fields that are custom field mapped.</li>
        <li>Shopify: fix issue with errors in sending mapped but empty <code>country_code_of_origin</code>.</li>
        <li>Automation Engine: fix issue with paginating xml response with pagination set in the response body.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.285',
    date: 'February 9th, 2024',
    title: 'Shopify/Magneto2 Upgrades + Walmart/Fitment/UI Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Shopify: Upgrade API to version <code>2023-07</code> from <code>2023-04</code>.</li>
        <li>Magento2: fulfilled orders now trigger tracking emails via the <code>notify</code> attribute.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Walmart: fix issue with updates ignoring override <tt>title</tt>.</li>
        <li>Fitment: fix issue with bulk files wiping fitment with <code>action edit</code>.</li>
        <li>Product Editor UI: fix issue with scrolling when clicking into certain channel description fields.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.284',
    date: 'February 2nd, 2024',
    title: 'eBay, Storefront + Automation Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>eBay: Update Trading API to version <code>1331</code>.</li>
        <li>eBay: orders import <code>order.details.paypal</code> field removed per <a href='https://developer.ebay.com/devzone/deprecated_trading_ordermgmt_fields.pdf' target='_blank' rel='noopener noreferrer'>API deprecation</a> - reference <code>order.details.payments.0</code> for the same data.</li>
        <li>Automation Engine: support export of <code>orders.meta.raw.order</code> fields.</li>
        <li>Storefront: Authorize.Net process approved and held transactions.</li>
        <li>Storefront: introduce checkout post sale variables <code>order_email order_name</code>.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>BigCommerce: fix issue with detecting string as valid YoutTube URL for <code>bigcommercevideos</code>.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.283',
    date: 'January 26th, 2024',
    title: 'Fitment + Automation Upgrades and Channel Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Product Editor: <code>bigcommerceavailability</code>  moved to dedicated toggle.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.283-bigcommerceavailability.png' alt='Product editor BigCommerce Availability' />
        </li>
        <li>Fitment: introduce support for tag to automatically redirect home page link to YMM results when YMM is engaged.</li>
        <li>Automation Engine: introduce <code>connection.pagination.page_per_second</code> to limit the number of paginated requests per second.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Bulk Orders: fix issue with errors processing <code>itemdetails</code>.</li>
        <li>Product editor: fix issue with random missing eBay dynamic item specifics.</li>
        <li>BigCommerce: fix issue with <code>sold</code> kit inventory updates when the kit is a variation parent.</li>
        <li>Google: fix regression with auto renew <code>expirationDate</code> that removed channel specific data.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.282',
    date: 'January 19th, 2024',
    title: 'Product Image Editor Replaced + Usage Limit Email Notifications',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Product Editor: replace photo editor with Filerobot Image Editor.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.282-image-editor-filerobot.png' alt='Product editor image editor Filerobot' />
        </li>
        <li>Usage Limits: email notifications go out to accounts that hit 70%, 90% and 100% thresholds.</li>
        <li>Automations: Brock Supply introduce catalog import.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Bulk: fix issue with erroneous error returned with uploading eBay dynamic item specifics.</li>
        <li>BigCommerce: fix issue with <code>bigcommercelistingid null</code> on <code>action end</code> with no listing id is already set.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.281',
    date: 'January 12th, 2024',
    title: 'BigCommerce + Shopify + eBay and Fitment Upgrades + Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>BigCommerce: introduce support for V3 videos format.</li>
        <li>BigCommerce: introduce support for not sending <code>upc</code> and <code>mpn</code>.</li>
        <li>Bulk Fitment: introduce support for <code>action=fitment</code> files containing only an <code>epid</code> column in lieu of year/make/model.</li>
        <li>Turn14: update tracking import with <code>shipservice</code> and <code>shipcarrier</code> mapping.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>eBay: fix issue with removing images not removing from listings.</li>
        <li>Shopify: fix issue with <code>description</code> not updating on <code>action edit</code>.</li>
        <li>Fitment: fix issue with YMM results showing for products not listed in a specific store.</li>
        <li>Admin: fix issue with permissions missing for admin to assign roles to new users.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.280',
    date: 'January 5th, 2024',
    title: 'Shopify, eBay, Storefront and Fitment Upgrades + Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Shopify: update to API version <code>2023-04</code>.</li>
        <li>Storefront: checkout use greatest dimensions for rate calculation in multi item carts.</li>
        <li>Storefront: introduce setting to generate Google Shopping Feed as XML instead of RSS.</li>
        <li>Fitment: introduce reset button and links to search results in product pages not matching YMM selected.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.280-fitment-upgrades.png' alt='Fitment YMM Upgrades' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Storefront: fix for rich text editor for pages and categories.</li>
        <li>Fitment: fix issue with results showing matches not in the YMM selection.</li>
        <li>BigCommerce: fixe issue with importing invalid <code>bigcommercevideos</code>.</li>
        <li>eBay: fix issue with international fixed price shipping errors preventing listing updates.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.279',
    date: 'December 29th, 2023',
    title: 'BigCommerce Fixes',
    content:
  <ul>
    <li><strong>Fixes</strong>
      <ul>
        <li>BigCommerce: fix issue with videos errors submitted in a legacy import format.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.278',
    date: 'December 22nd, 2023',
    title: 'Amazon + Shopify Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>BigCommerce: order import <code>discountcode</code> via <code>applied_discounts</code> when type is <code>coupon</code>.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.278-bigcommerce-discountcode.png' alt='BigCommerce Discount Code' />
        </li>
        <li>Storefront: checkout use greatest dimensions for rate calculation.</li>
        <li>Storefront: Google feed remove non-printable characters from description.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Magento2: fix issue with tracking not sending.</li>
        <li>Amazon: fix issue with variation processing of <code>VariationTheme</code>.</li>
        <li>Shopify: fix issue where not all fields were being sent on bulk <code>relist</code>.</li>
        <li>Fitment: fix issue with facet fields not populating the module from product changes.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.277',
    date: 'December 15th, 2023',
    title: 'Channels + Channel Errors UI + Fitment Upgrades and Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Channel Errors UI: introduce filtering for only errors, warnings and show errors in the list view.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.277-channel-errors-ui.png' alt='Channel Errors UI' />
        </li>
        <li>eBay: upgraded dynamic item specifics interface.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.27-ebay-item-specifics-ui.png' alt='eBay Item Specifics UI' />
        </li>
        <li>Magento2: introduce <code>use_sha256</code> option to allow for SHA256 signatures.</li>
        <li>BigCommerce: Change order of processing V2 variation option name to use display name first.</li>
        <li>BigCommerce: Introduce support for uploading video urls via <code>bigcommercevideos</code> which accepts a JSON array of video URLs.</li>
        <li>Storefront: Authorize.Net only process approved transactions.</li>
        <li>Fitment: BigCommerce introduce category tree mirroring structure of the store.</li>
        <li>Automations: Meyer Distributing drop shipping upgraded to support <code>LTL</code> shipments.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Amazon: fix issue with missing <code>DepartmentName</code> in various categories.</li>
        <li>Amazon: fix issue with imports not mapping <code>amznparentsku amznparentasin</code>.</li>
        <li>Fitment: fix issue with conversion to <code>EPIDs</code> returning <code>null</code> values.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.276',
    date: 'December 8th, 2023',
    title: 'eBay Dynamic Specifics, Automation Engine + API Upgrades and Channel Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>eBay: introduce interface to support managing dynamic item specifics.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.276-ebay-dynamic-specifics.png' alt='eBay Dynamic Specifics' />
        </li>
        <li>Automation Engine: JSON encode <code>triggers</code> values so they may be parsed in the <code>template</code>.</li>
        <li>Storefront: require Recaptcha for all support and account email forms.</li>
        <li>Settings API: allow full setting of inventory field order by setting <code>site_cart_stock_by_reset true</code> or <code>site_cart_kit_by_reset true</code>.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Magento2: fix issue with imports failing from malformed SKUs.</li>
        <li>Google: fix issue with auto renew by setting <code>expirationDate</code> one month in the future.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.275',
    date: 'December 1st, 2023',
    title: 'BigCommerce + Automation Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>BigCommerce: introduce support for both string and JSON input on <code>bigcommercesharedoptionid</code>.</li>
        <li>Automation Engine: introduce support for <code>ignoreField ignoreValue</code> with order <code>billing shipping</code> fields.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.274',
    date: 'November 24th, 2023',
    title: 'BigCommerce Fixes',
    content:
  <ul>
    <li><strong>Fixes</strong>
      <ul>
        <li>BigCommerce: fix issue with variation display names not matching option set values.</li>
        <li>BigCommerce: fix issue with linking duplicate listings to multiple products in SureDone that do not match <code>SKU</code>.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.273',
    date: 'November 17th, 2023',
    title: 'Amazon + Automation Engine Upgrades and Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Amazon: introduce performance upgrades for order imports.</li>
        <li>Amazon: orders import <code>BuyerCompanyName</code> as order <code>shippingcompany</code>.</li>
        <li>Amazon: imports migrated to the new user interface.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.273-amazon-imports.png' alt='Amazon Imports Interface' />
        </li>
        <li>Orders UI: introduce copy address button from Billing/Shipping.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.273-orders-copy-button.png' alt='Orders Address Copy Button' />
        </li>
        <li>Automation Engine: introduce <code>export_empty_file</code> config which allows for an empty file to be sent on export when no object match criteria.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Fitment: fix issue with duplicate variants showing in result sets.</li>
        <li>BigCommerce: fixed several small issues preventing listing updates when bad data in custom fields is encountered.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.272',
    date: 'November 10th, 2023',
    title: 'Channel Order Shipping Service Mapping Interface + Shopify Tags Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Channel Orders: introduce interface for setting orders shipping service mapping.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.272-orders-default-ship-mapping.png' alt='Orders Default Ship Mapping Interface' />
        </li>
        <li>Shopify: support of dedicated <code>shopifytags</code> in listing and import.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.271',
    date: 'November 3rd, 2023',
    title: 'Bulk Fitment + Walmart Upgrades and Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Walmart: automatically map shipping template on integration/instance creation.</li>
        <li>Bulk Fitment: upgrading matching to be case insensitive.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Bulk Fitment: fix issue with converted orphan files showing in the results interface.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.270',
    date: 'October 27th, 2023',
    title: 'Amazon + eBay + Walmart Upgrades and Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Amazon: imports migrated to new underlying SP-API systems.</li>
        <li>eBay: introduce support for Condition Grading via <code><a href='https://developer.ebay.com/Devzone/XML/docs/Reference/eBay/types/ConditionDescriptorType.html' target='_blank' rel='noopener noreferrer'>ConditionDescriptor</a></code>.</li>
        <li>Automations: Keystone Cost Import support <code>keystonemap</code> via <code>MAPretail</code> field.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Walmart: fix issue with listing updates being throttled to well under the thousands allowed per feed send.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.269',
    date: 'October 20th, 2023',
    title: 'Amazon/Etsy/BigCommerce/WebShopManager/Storefront/Automations Upgrades and Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Automation Engine: export <code>order.details.field</code> array field if array.</li>
        <li>Amazon: support saving order item <code>itemdetails.is-prime true</code> indicator.</li>
        <li>BigCommerce: update custom fields to not send any mapped values numerically <code>0</code>.</li>
        <li>Storefront: Checkout thank you page includes <code>cart</code> payload for tracking.</li>
        <li>WebShopManager: launch CSV integrations for catalog update.</li>
        <li>Automations: DAS change FTP host to <code>secureorders.dasinc.com</code>.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Etsy: fix issue where some import headers could be duplicated in a case-insensitive manner.</li>
        <li>Automation Engine: fix issue with <code>action fitment</code> fields being ignored when not in the database.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.268',
    date: 'October 13th, 2023',
    title: 'Channel Orders Shipping Carrier and Service Mapping Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Amazon: orders introduce support for setting <code>shippingcarrier</code> via service mapping.</li>
        <li>eBay: orders introduce support for setting <code>shippingcarrier</code> via service mapping.</li>
        <li>eBay: orders import <code>shippingcarrier</code> for named FedEx, UPS and USPS services.</li>
        <li>Etsy: orders introduce support for setting <code>shippingcarrier</code> via service mapping.</li>
        <li>Walmart: orders introduce support for setting <code>shippingcarrier</code> via service mapping.</li>
        <li>Automations: introduce GunBroker integrations.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.268-gunbroker-integrations.png' alt='GunBroker integrations' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>BigCommerce: fix issue with <code>sold</code> variations.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.267',
    date: 'October 6th, 2023',
    title: 'Walmart and BigCommerce Upgrades + Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Walmart: introduce copy of shipping templates in settings.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.267-walmart-shipping-templates.png' alt='Walmart Shipping Templates Download' />
        </li>
        <li>Walmart: introduce support for default shipping template ID dropdown.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.267-walmart-shipping-templates-list.png' alt='Walmart Shipping Templates' />
        </li>
        <li>Automation Engine: change error to warning for FTP message <code>no files found</code>.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>BigCommerce: fix issue with custom fields errors on <code>relist</code>.</li>
        <li>BigCommerce: fix issue with <code>relist</code> on variation children when parent is reset.</li>
        <li>BigCommerce: fix issue with <code>bigcommercechannels</code> returning incorrect format via the API and exports.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.266',
    date: 'September 29th, 2023',
    title: 'Reports, Automations, Usage, Channels Upgrades + Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>

        <li>Reports: add order <code>items.source</code> to the Per Order Profit Margins.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.266-reports-per-order-profit-margins-source.png' alt='Reports Per Order Profit Margins Source' />
        </li>
        <li>Amazon: field mapping interface now sets unit of measure as an input.</li>
        <li>Fitment: update YMM with a go button instead of automatic redirects to results.</li>
        <li>Usage: remove <tt>listing limits</tt> as part of usage calculation.</li>
        <li>Automations: introduce Brock Supply integrations.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.266-brock-supply-integrations.png' alt='Brock Supply Integrations' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>eBay: fix issue missing default item specifics mapping on instance creation.</li>
        <li>eBay: fix issue with <code>ItemSpecifics</code> sending erroneously on update.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.265',
    date: 'September 22nd, 2023',
    title: 'Reports, Automations, Channels Upgrades + Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Reports: introduce user permissions for reading reports.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.265-reports-permissions.png' alt='Reports permissions' />
        </li>
        <li>Automation Engine: introduce support for <code>order.details itemdetails</code> field key mapping.</li>
        <li>BigCommerce: return warnings when listing returns duplicate title error and matches to existing listing.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Bigcommerce: fix issue with product image not found.</li>
        <li>eBay: fix issue with importing fitment due to incorrect <code>siteId</code>.</li>
        <li>Fitment: fix issue with bulk fitment uploads matching too many vehicles.</li>
        <li>Automation Engine: fix issue with <code>vendor_actions.search</code> breaking with non alphanumeric characters in field mapping.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.264',
    date: 'September 15th, 2023',
    title: 'Channels Upgrades + Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Amazon: update product attribute models per mandates.</li>
        <li>Amazon: improve products, inventory and prices feed processing performance.</li>
        <li>Shopify: Imports introduce support for <code>shopifydescription</code>.</li>
        <li>Automations: introduce new inventory, drop shipping and tracking integration with Nivel.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.264-nivel-integrations.png' alt='Nivel integration' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Storefront: GA4 fix issue with tracking event.purchase items and currency.</li>
        <li>Etsy: fix issue with variation field duplication.</li>
        <li>eBay: fix issue with saving empty inline <code>ebayitemspecifics</code> slowing performance.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.263',
    date: 'September 7th, 2023',
    title: 'eBay Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Storefront: force all session cookies to be secure.</li>
        <li>Orders: kit explode introduce setting optional field <code>componentexploded 1</code>.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>eBay: fix issue with imports overwriting inventory with item specific.</li>
        <li>eBay: fix issue with <code>relist</code> wiping out <code>ItemSpecifics</code> saved in the database.</li>
        <li>eBay: fix issue with order imports failing attempting to pull orders older than 90 days.</li>

      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.262',
    date: 'September 1st, 2023',
    title: 'BigCommerce Upgrades + Etsy Fix',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>BigCommerce: import <code>channel_id storefront_name</code> to <code>order.details</code>.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Etsy: fix issue with errors resulting from default return policy id.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.261',
    date: 'August 25th, 2023',
    title: 'BigCommerce/ShipStation/Fitment Upgrades + eBay/Turn14 Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>BigCommerce: introduce support for <code>bigcommercechannels</code> to assign a product in a multi store environment.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.261-bigcommerce-channels.png' alt='BigCommerce Channels Support' />
        </li>
        <li>ShipStation: introduce support for <code>bigcommerceproductoptions</code> as custom field export.</li>
        <li>Fitment: introduce support for <code>action=fitment</code> pass through of <tt>Notes</tt>.</li>
        <li>Orders UI: order item fields with list values now match case insensitive.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.261-order-items-ui-select-list.png' alt='Order items select list improvements' />
        </li>
        <li>Automations: introduce new drop shipping and tracking integration with NPW.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.261-npw-integration.png' alt='NPW integration' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>eBay: fix issue with edge case order items variations not linking properly.</li>
        <li>Turn14: fix issue with Cost import sending too many updates when <code>turn14map</code> is blank.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.260',
    date: 'August 18th, 2023',
    title: 'Channel/Automations Upgrades + Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>WebShopManager: introduce <code>tieredPricing</code> support.</li>
        <li>ASAP Network: Update catalog import to include more item specifics.</li>
        <li>AutoParts4Less: fitment uploads split into batches of 1000 vehicle ids per SKU.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Search: fix error when searching on base field.</li>
        <li>BigCommerce: fix issue with saving <code>bigcommercetype</code> case sensitive.</li>
        <li>eBay: fix issue with wiped <code>ItemSpecifics</code> on <code>edit</code> when using mixed mapped and inline fields.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.259',
    date: 'August 11th, 2023',
    title: 'Channel Errors Interface and Amazon/BigCommerce/eBay/Walmart Upgrades + Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Channels: introduce new channel errors interface which aggregates product errors per channel instance.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.259-channel-errors-menu.png' alt='Channel errors menu' />
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.259-channel-errors-interface.png' alt='Channel errors interface' />
        </li>
        <li>Amazon: introduce support for <code>amznparentsku</code> to allow for an alternative to the default generated parent SKU extension format.</li>
        <li>Walmart: update status syncing by <code>walmartsku</code> to accommodate sellers that have unique Walmart SKUs vs SKUs that are in SureDone.</li>
        <li>ASAP Network: upgrade catalog import to pull in more item specifics.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>BigCommerce: fix issue with saving <code>bigcommercelistingid null</code>.</li>
        <li>eBay: fix issue with image counts over 12 being sent in incorrect order.</li>
        <li>eBay: fix issue with order <code>total</code> double counting tax per prior <code>eBayCollectAndRemitTax</code> upgrades.</li>
        <li>Automation Engine: fix issue with xlsx files not processing for order imports.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.258',
    date: 'August 4th, 2023',
    title: 'AutoParts4Less, Etsy, Walmart and NetSuite and Logs Upgrades + Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Etsy: introduce support for <code>etsyreturnpolicyid</code>.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.258-etsy-return-policy-id.png' alt='Etsy Return Policy ID Settings' />
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.258-etsy-return-policy-id-editor.png' alt='Etsy Return Policy ID Product Editor' />
        </li>
        <li>AutoParts4Less: introduce support for B2B wholesale via <code>autoparts4lesswholesalequantity</code> and <code>autoparts4lesswholesalediscount</code>.</li>
        <li>NetSuite: introduce support for integration via SFTP.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.258-netsuite-sftp-integrations.png' alt='NetSuite SFTP Integrations' />
        </li>
        <li>Automations: introduce integrations with DAS Inc.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.258-das-inc-integrations.png' alt='DAS Inc Integrations' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Usage: fix issue with listings update calculation.</li>
        <li>Logs UI: fix issue with pages beyond 1001 results not showing.</li>
        <li>Walmart: fix issue with logging identifier as <code>walmart-add walmart-edit</code>.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.257',
    date: 'July 28th, 2023',
    title: 'Logs + Automation Engine + Channels Upgrades + Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Logs UI: added <tt>warnings</tt> to detail page.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.257-logs-ui-warnings.png' alt='Logs UI Warnings' />
        </li>
        <li>Amazon: improve imports by checking for and discarding duplicate images of different sizes.</li>
        <li>PremierWD: Batch cost imports to handle larger catalog sizes.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Automation Engine: fix issue with integrations using <code>limit_batch_size</code> getting stuck.</li>
        <li>Etsy: fix issue with retries not working when encountering <code>Exceeded per second rate limit</code> error.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.256',
    date: 'July 21st, 2023',
    title: 'Amazon / BigCommerce / Walmart Upgrades + Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>API: return <code>skuvaultlocationinventory</code> as array.</li>
        <li>Amazon: improve performance by splitting up inventory and price updates.</li>
        <li>Automation Engine: introduce support for file export <code>enclosure</code>.</li>
        <li>Turn14: enable support for <tt>Preferred Access</tt> which chooses the lowest cost shipping option.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.256-turn14-preferred-access.png' alt='Turn14 Preferred Access' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Walmart: fix issue with imports failing due to report type deprecation.</li>
        <li>BigCommerce: fix issue with erroneous retries causing bulk files to get stuck.</li>
        <li>Product Editor: fix issue with <tt>black dot</tt> showing up in middle of interface.</li>
        <li>Logs UI: fix issue with default columns disappearing.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.255',
    date: 'July 14th, 2023',
    title: 'Amazon / AutoParts4Less / eBay / Facebook Updates + Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Facebook: Upgraded to Graph API v17.0.</li>
        <li>eBay: capture <code>rLogId</code> as part of the log response.</li>
        <li>eBay: notice and confirmation by eBay of issue addressed in <code>v3.250</code> <tt>As of August 14, 2023, DOCTYPE declarations must be used within the CDATA section of your XML requests payload during listing updates. DOCTYPE will not be otherwise allowed in XML request payloads in Trading API. Please follow this practice when using the Item.Description field in Add/Revise/Relist Trading API calls.</tt>. Please remove this declaration from your eBay listing template, especially if you are using a custom template.</li>
        <li>AutoParts4Less: introduce brand mapping to convert case sensitive brands to accepted values.</li>
        <li>Reports: increase the default limit for <tt>Per Order Profit Margins</tt>.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.255-report-limits.png' alt='Report default limits' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Amazon: fix issue with Jewelry category processing from attribute update rollout.</li>
        <li>Amazon: fix edge case issue with missed updates when hitting feed or memory limits.</li>
        <li>Automation Engine: fix issue with <code>file_configs.headers</code> not accepting a boolean value to allowing preventing file headers from being sent with exports.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.254',
    date: 'July 7th, 2023',
    title: 'Amazon + BigCommerce/Shopify/Magento2 Upgrades + Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Amazon: update product attribute models per mandates.</li>
        <li>Shopify: introduce setting for the default <tt>inventory policy</tt> and API/UI support for <code>shopifyinventorypolicy</code>.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.254-shopify-inventory-selling.png' alt='Shopify inventory selling option' />
        </li>
        <li>BigCommerce: update product editor to remove internal ids for custom fields and variants improving <tt>create similar</tt>.</li>
        <li>Fitment: updated product page YMM widget.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.254-fitment-ymm-table.png' alt='Fitment YMM table' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Magento2: fix issue with order logs not setting correct <code>channelId</code>.</li>
        <li>Orders: create items set product before importing mapped fields, fixes issue with missing import field mapping on exploded kit components.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.253',
    date: 'June 29, 2023',
    title: 'UI Search Builder and BigCommerce Upgrades + Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>UI: introduced new search builder interface.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.253-ui-search-builder-interface.png' alt='Search builder' />
        </li>
        <li>BigCommerce: upgrade import to support <code>bigcommercesharedoptionid</code> for V3 catalog variations.</li>
        <li>Orders: invoices can now include order item details.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>BigCommerce: fix issue with duplicate SKU requests causing bulk file processing backups.</li>
        <li>Bulk Exports UI: fix issue with search not normalizing queries with special characters.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.252',
    date: 'June 23, 2023',
    title: 'Automations Upgrades + Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Automations EDI: relaunch new 850 997 and 856 support over SFTP.</li>
        <li>AutoParts4Less: remove html from all product creation and update descriptions.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Amazon: fix issue with orders not importing due to throttle restrictions with their APIs.</li>
        <li>Etsy: fix issue with using <code>relist</code> from a variation parent would not list child variations.</li>
        <li>WebShopManager: fix issue with failed runs due to size limitations of the WSM API.</li>
        <li>WPS: fix issue with drop ship error <code>The ship phone must be a string.</code>.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.251',
    date: 'June 16, 2023',
    title: 'Storefront and Search Interface Upgrades + Fitment Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Storefront: migrate Google Universal Analytics to Google Analytics 4, see the <a href='https://support.suredone.com/support/solutions/articles/1000318168-google-universal-analytics-migration-to-google-analytics-4' target='_blank' rel='noopener noreferrer'>migration guide</a> for more details.</li>
        <li>Search: launch new user experience with hints for search functionality.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.251-search-interface-hints.png' alt='Search hints upgrade' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>BigCommerce: fix V3 regression with <code>inventory_level 0</code>.</li>
        <li>Fitment: fix issue with <code>empty column</code> errors.</li>
        <li>Fitment: Fix issue where including invalid fitment headers in the bulk file would cause data mismatching and epid lookups to fail.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.250',
    date: 'June 9, 2023',
    title: 'Channel and Orders Upgrades + Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Amazon: orders support <code>discounttotal</code> calculated via <code>item-promotion-discount</code>.</li>
        <li>eBay: Introduce support to remove <a href='https://developer.ebay.com/devzone/xml/docs/reference/ebay/types/ShippingServiceCostOverrideListType.html' target='_blank' rel='noopener noreferrer'><code>ShippingServiceCostOverrideList</code></a> via <code>ebayshippingcostoverride1type ebayshippingcostoverride1cost ebayshippingcostoverride1additionalcost 0</code>.</li>
        <li>Etsy: send SKU for non-variation products.</li>
        <li>Templates: introduce support <code>dechex hexdec</code> functions.</li>
        <li>Orders: invoices can now include order item custom fields.</li>
        <li>Shipments: support multi line reference notes.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.250-shipment-label-references.png' alt='Shipment label references' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Reports: fix issue with date hours not using 24 hour time.</li>
        <li>Automation Engine: fix issue with batched processing missing edge case syncing with large catalogs.</li>
        <li>Amazon: fix issue with importing duplicates of images with different sizes.</li>
        <li>eBay: fix issue with new error <code>XML Error Text: &quot;A DOCTYPE is not allowed in content&quot;.</code>.</li>
        <li>eBay: fix issue with error <code>21916720</code> <tt>Cannot list with both product listing details and item compatibilities</tt> for listings with both fitment and product ids.</li>
        <li>Etsy: fix issue with <code>etsylistingid</code> not being saved after creation when the subsequent update to set the status <code>active</code> failed.</li>
        <li>Walmart: fix issue with logging <code>identifier</code> when using unique channel <code>sku</code>.</li>
        <li>WPS: fix issue with drop shipping failing due to empty response.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.249',
    date: 'June 2, 2023',
    title: 'BigCommerce and Shopify Upgrades + Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Automation Engine: support address page params via <code>PAGE_NUM</code>.</li>
        <li>BigCommerce: variation support for <code>shared_option_id</code>.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Amazon: fix issue with missing feed processing log <code>errors</code>.</li>
        <li>Shopify: fix issue with inventory not respecting min/max stock.</li>
        <li>Shopify: fix issue with fulfillment using the <code>shippingcarrier</code> instead of <code>shipments.shipcarrier</code>.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.248',
    date: 'May 26, 2023',
    title: 'Channel + Automations Upgrades and Kit Sync Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Walmart: Remove deprecated <code>ProductTaxCode</code>.</li>
        <li>BigCommerce: custom field management improvements and fixes.</li>
        <li>Bulk Exports: introduce link to failed requests in the interface.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.248-bulk-export-failures.png' alt='Bulk Export Failure Link' />
        </li>
        <li>MyFitment: enable configurable fitment import field.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.248-myfitment-mapping.png' alt='MyFitment import field mapping' />
        </li>
        <li>Turn14: support import of <code>turn14jobber</code> in the <tt>Cost Import</tt> integration.</li>
        <li>Torqued Distribution: introduce <tt>Cost Import</tt> integration.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Logs UI: fix issue with <code>errors</code> not showing on single log pages.</li>
        <li>Kits: fix issue with <code>sold</code> inventory updates setting 0 channel inventory.</li>
        <li>Bulk Fitment: fix issue with jobs not completing for parts with thousands of fitment associated.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.247',
    date: 'May 19, 2023',
    title: 'Automation Engine and Channels and Bulk File API Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>eBay: skip importing orders with <code>SalesRecord 0</code> to accommodate eBay API bug still pending.</li>
        <li>Automation Engine: Introduce the <code>limit_template_size</code> config which allows users to specify a size limit in  in megabytes for an item template to be set.</li>
        <li>Bulk File API: introduce <code>/v1/bulk/jobs/DATE</code> endpoint where the date can be submitted to return jobs for that day.</li>
        <li>WebShopManager: introduce listings disable integration.</li>
        <li>Automations UI: link to the last log run.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.247-automations-last-log-run.png' alt='Automations last log run link' />
        </li>
        <li>BigCommerce: rollout V3 variation management to all users, introduce editor interface for managing dummy parents.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.247-bigcommerce-v3-dummy-parent-interface.png' alt='BigCommerce V3 dummy parent interface' />
        </li>
        <li>Etsy: introduce new attributes interface.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.247-etsy-attributes-ui.png' alt='Etsy attributes interface' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Bulk Exports: fix issue for some exports failing due to size limit.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.246',
    date: 'May 12, 2023',
    title: 'Automation Engine and Bulk Upgrades + Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Media Manager: users with custom enterprise buckets can now use new interface.</li>
        <li>Automation Engine: switch order of processing for <code>field_formats</code> after <code>vendor_actions</code>.</li>
        <li>Automation Engine: iterate and rename <code>search_limit search_limit_max</code> as follows:
          <ul>
            <li><code>limit_export</code>: number when <tt>export</tt> and <code>payload_multi: true</code>, use this number to limit the amount of objects included in the request and split into multiple requests.</li>
            <li><code>limit_import</code>: number when import and <code>payload_multi: false</code>, limit the total amount of objects processed.</li>
            <li><code>limit_batch_size</code>: number to split the automation into parallel runs of this many objects each.</li>
          </ul>
        </li>
        <li>Bulk File API: Introduce the <code>stage</code> parameter to <code>upload</code> endpoint. When <code>stage = true</code>, the bulk file uploaded will be moved to a temporary storage location so it my be processed and/or transformed by rules via Automation Engine.</li>
        <li>Bulk Exports: introduce indexed log for <code>bulk-export-expired</code>.</li>
        <li>Shopify: Notify customers when orders are fulfilled per API version <code>10-2022 fulfillment.notify_customer true</code>.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Automation Engine: fix issue where certain types of XML payloads are corrupted when logging the censored version.</li>
        <li>Parts Unlimited: fix issue with inventory files failing download since their maintenance window due to needing an increased timeout.</li>
        <li>Shopify: fix issue with errors on updating product variations.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.245',
    date: 'May 5, 2023',
    title: 'Bulk Export + Channel Upgrades + Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Bulk Exports: failed requests due to size limit are now logged and expired after 1 hour.</li>
        <li>BigCommerce: V3 variations supported, <tt>private beta</tt>.</li>
        <li>Channels: Custom label setting was added to Amazon, Etsy and Walmart interfaces.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.245-channel-instance-labels.png' alt='Channel instance labels' />
        </li>
        <li>Orders UI: show the channel custom label if one is available.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.245-orders-channel-instance-label.png' alt='Orders channel instance label' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Kits: fix issue with <code>sold</code> inventory updates not including pre-assembled kits.</li>
        <li>Walmart: fix issue with ship tracking not syncing line items when <code>orders.items.sku</code> does not match the channel SKU.</li>
        <li>BigCommerce: Fix issue with <code>bigcommerceisvisible</code> from the V3 upgrade.</li>
        <li>WebShopManager: fix issue with listing requests failing.</li>
        <li>Torqued Distribution: Fix issue with inventory and catalog imports using incorrect import SKU.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.244',
    date: 'April 28, 2023',
    title: 'Channels + Report/Billing Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Channels: integration creation and deletion now logged.</li>
        <li>Core: fix issue with stuck bulk jobs from creation of duplicate SKUs in same file.</li>
        <li>DCi: import new fields <code>WheelDiameter WheelWidth NegativeOffset PositiveOffset</code>.</li>
        <li>WebShopManager: introduce support for <code>wsmskip 1</code> to prevent creation of new listings.</li>
        <li>Shopify: send only updated data to the channel.</li>
        <li>Amazon: Fields <code>minstock maxstock</code> are now mappable via the settings.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.244-amazon-min-max-stock-mapping.png' alt='Amazon minstock maxstock mapping' />
        </li>
        <li>Etsy: authorization and settings moved to new interface.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.244-etsy-settings-interface.png' alt='Etsy settings interface' />
        </li>
        <li>Reports: Order handling added fields <code>date dateutc</code> and product <code>brand</code>.</li>
        <li>Reports: Unsold SKUs updated to include filter by channel.vv
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.244-report-unsold-skus-channel-filter.png' alt='Report Unsold SKUs Channel Filter' />
        </li>
        <li>Billing UI: added a link to view invoices.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.244-billing-ui-invoices.png' alt='Billing interface invoices' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>eBay: fix issue with edge case <code>price</code> overriding <code>ebayprice</code>.</li>
        <li>Automations UI: fix issue with logs not showing for shared integrations.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.243',
    date: 'April 21, 2023',
    title: 'New Report + Automation Engine and Channel Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>eBay: orders missing <code>SalesRecord</code> are no longer imported.</li>
        <li>Automation Engine: introduce function to reset testing and production trigger statuses.</li>
        <li>Automation Engine: change log <code>identifier</code> for shared integrations as <code>automation-shared-sharedId</code>.</li>
        <li>Reports: introduce new <tt>Order Handling Time</tt> report.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.243-order-handling-report.png' alt='Report Order Handling' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Walmart: fix issue with parsing weight.</li>
        <li>Etsy: fix issue with product editor missing attributes in V3 release.</li>
        <li>Fitment: fix issue with new listings requiring a <code>relist</code> to update fitment module.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.242',
    date: 'April 14, 2023',
    title: 'AutoParts4Less Upgrades + eBay/Magento Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>AutoParts4Less: introduce support for <code>autoparts4lesstitle</code>.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>eBay: fix issue where the wrong signature headers were getting sent for <tt>MaaS</tt> users causing failed jobs.</li>
        <li>Magento: Fix issue with interface overlapping fields.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.242-magento-ui-fix.png' alt='Magento UI Attributes Fix' />
        </li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.241',
    date: 'April 7, 2023',
    title: 'Etsy + AutoParts4Less Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Etsy: upgrade to the V3 API.</li>
        <li>AutoParts4Less: introduce full refresh option for inventory and pricing sync.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.241-autoparts4less-inventory-refresh.png' alt='AutoParts4Less Inventory Refresh' />
        </li>
        <li>Meyer Distributing: Implement <code>CollectedSalesTax Yes</code> for orders with <code>taxtotal &gt; 0</code>.</li>
        <li>DCi: support import of <tt>brand code</tt>.</li>
        <li>Fitment: introduce support for filtering native <tt>search</tt> with fitment results.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Product Editor: fixed issue with tooltips layout.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.240',
    date: 'March 31, 2023',
    title: 'BigCommerce + Automation Upgrades and Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Bulk: introduce dedicated infrastructure for faster enterprise processing and performance. Contact support for more information.</li>
        <li>Media Manager: introduce upgrades to delete multiple files and create invalidations for existing assets.</li>
        <li>BigCommerce: show error in interface for <tt>option set id</tt> that do not exist.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.240-bigcommerce-option-set-error.png' alt='BigCommerce Option Set ID Error' />
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.240-bigcommerce-option-set-valid.png' alt='BigCommerce Option Set ID Valid' />
        </li>
        <li>BigCommerce: update v2 variation processing to restrict updates to only update fields sent.</li>
        <li>Automation Engine: Migrate the <code>uppercase/lowercase</code> function to <code>vendor|suredone_actions</code>.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>BigCommerce: fix <code>logs.action</code> in V3 implementation.</li>
        <li>Automation Engine: fix issues with using the <code>combine</code> action on <code>identifier</code>.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.239',
    date: 'March 24, 2023',
    title: 'Channel Upgrades + Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Core: introduce new channel listing report for sharing the state of listings across the catalog. Please contact support for access to this new report in private beta.</li>
        <li>Automation Engine: improvements to <code>response_field_map</code> processing.</li>
        <li>Shopify: upgrade API version to <code>10-2022</code> which also limits the number of shipments to 1 per order.</li>
        <li>eBay: shipping profiles show up in alphabetical order in settings.</li>
        <li>AutoParts4Less: introduce support for <code>autoparts4lesscategory</code>.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>BigCommerce: fix issue with V3 regression not sending images on listing creation.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.238',
    date: 'March 17, 2023',
    title: 'Automation Engine Upgrades + eBay/BigCommerce Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Core: Update infrastructure to support more requests.</li>
        <li>Automation Engine: Migrate the <code>combine</code> function to <code>vendor|suredone_actions</code>.</li>
        <li>AutoParts4Less: replace product create and update with new bulk <code>add-update</code> endpoints. Further addresses issue of products being stuck in <code>PENDING</code> status.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>API: fix issue with kit auto calculate <tt>price cost msrp</tt> fields always syncing auto calculated price even when overridden.</li>
        <li>eBay: fix issue with <tt>Compatibility</tt>  error <code>Invalid compatibility combination(s). LongMessage - One or more compatibility combinations are invalid. Name, value, or name-value pair are not recognized.</code>.</li>
        <li>BigCommerce: fix issue with sending images even when no changes are made.</li>
        <li>BigCommerce: fix issue with <code>relist</code> not working for products that already exist on BigCommerce.</li>
        <li>Automation: fixed issue with Torqued Distribution SKU import not creating new products.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.237',
    date: 'March 10, 2023',
    title: 'Automation Engine + Media Manager + BigCommerce Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Automation Engine - Enable support for SFTP key files by setting <code>connection.is_key_file true</code> and pasting the contents of the key file into the <code>connection.password</code> config.</li>
        <li>Logs API: Introduce <code>found_more_logs</code> to response as boolean flag to determine if there are more logs than the <code>found</code> entry states.</li>
        <li>Media Manager UI: migrate to new interface <a href='https://app.suredone.com/settings/assets' target='_blank' rel='noopener noreferrer'>settings</a>.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.237-media-manager.png' alt='Media Manager UI' />
        </li>
        <li>BigCommerce: upgrade <tt>custom fields</tt> data model and enable support for names with multiple values.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.237-bigcommerce-custom-fields-multiple.png' alt='BigCommerce Custom Fields UI SureDone' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Facebook: fix issue with authorization failing.</li>
        <li>Walmart: fix issue where some categories were being incorrectly filtered.</li>
        <li>Automation: fix issue with Meyer Distributing cost import to use <code>Meyer Part</code> field to match to <code>meyersku</code>.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.236',
    date: 'March 3, 2023',
    title: 'Automation Engine + BigCommerce/Magento2 Upgrades + Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Automation Engine: Update <code>vendor_actions.setAddress</code> to allow for the use of the value <code>email</code> to dynamically set email address based on order data.</li>
        <li>BigCommerce: upgrade product imports to use the V3 API and support import of V3 variations.</li>
        <li>Magento2: introduce <code>disable_ssl</code> setting for connecting to stores with non-standard SSL configurations.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>eBay: fix issue with missing ad fees from orders.</li>
        <li>Shopify: fix issue with orders pagination.</li>
        <li>BigCommerce: fix issue with <tt>Create Similar</tt> causing errors with custom fields and images.</li>
        <li>Bulk Fitment: fix issue with year make model combinations not matching latest vehicles.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.235',
    date: 'February 24, 2023',
    title: 'UI Upgrades + Channel Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Logs UI: Specific log page now shows related logs.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.235-logs-ui-related.png' alt='related logs ui' />
        </li>
        <li>BigCommerce: introduce retries for custom field processing.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>BigCommerce: fix regression for V3 rollout for action <code>relist</code>.</li>
        <li>BigCommerce: fix regression with V3 upgrade for image processing.</li>
        <li>eBay: fix order <code>details</code> and re-submit tracking for orders affected between Feb 15 and 22.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.234',
    date: 'February 17, 2023',
    title: 'UI Upgrades + Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>API: return <code>400</code> and <code>404</code> error codes for deprecated endpoints.</li>
        <li>UI: Products and Inventory settings migrated to new interface.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.234-ui-products-inventory-settings.png' alt='products and inventory settings' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Shopify: fix issue with product description editor.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.234-shopify-description-editor.png' alt='Shopify description editor' />
        </li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.233',
    date: 'February 10, 2023',
    title: 'BigCommerce Upgrades + Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>BigCommerce: enable V3 API for all users, non-variations.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>BigCommerce: fix issue with V3 upgrade not sending V2 API api call for set <code>option_set_id</code>.</li>
        <li>Amazon: fix issue when sending multiple ASINs returning error <code>Value entered is not a valid ASIN</code>.</li>
        <li>Fitment: fix issue with with sending <code>fitmenttype</code> not triggering compatibility updates on storefronts.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.232',
    date: 'February 3, 2023',
    title: 'Channel + Automation Engine Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>BigCommerce: introduce support for the V3 API, non-variations.</li>
        <li>Etsy: Updated <code>when_made 2020_2023</code> value.</li>
        <li>Automation Engine: import order item user field maps on updated order items using <code>sold</code>.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>AutoParts4Less: fix issue with <code>condition</code> not mapping correctly.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.231',
    date: 'January 27, 2023',
    title: 'eBay Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>eBay: Update Trading API to version <code>1291</code>.</li>
        <li>eBay: Introduce support for up to 24 listing images.</li>
        <li>eBay: Introduce support for <a href='https://developer.ebay.com/devzone/xml/docs/reference/ebay/types/ShippingServiceCostOverrideListType.html' target='_blank' rel='noopener noreferrer'><code>ShippingServiceCostOverrideList</code></a> via <code>ebayshippingcostoverride1type ebayshippingcostoverride1cost ebayshippingcostoverride1additionalcost</code>.</li>
        <li>API: deprecate <code>v1/authorize</code> endpoint for channel authorizations.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>API: fix issue with internal use triggering app and api throttle limits.</li>
        <li>Logs UI: fix issue with missing <code>errors</code> field.</li>
        <li>Facebook: fix issue with pagination on larger imports.</li>
        <li>Automation Engine: fix issue with comparisons of empty and 0 strings not working as expected.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.230',
    date: 'January 20, 2023',
    title: 'Amazon + eBay Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>eBay: Prevent listing restricted products on eBay Motors by checking <tt>brand MPN</tt> combo.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Amazon: fix issue with new auth calls returning <code>Settings are not valid</code>.</li>
        <li>Amazon: fix issue with canceling orders relying on <code>isBuyerCanceledRequest</code>.</li>
        <li>Amazon: fix issue with error <code>Amazon sync is turned off</code> showing for disabled integrations.</li>
        <li>Logs UI: fix issue with channel filters searching <code>amazon</code> instead of <code>amzn</code>.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.229',
    date: 'January 13, 2023',
    title: 'Amazon + eBay + Shopify Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Core: introduce support for sold channel min/max stock checking.</li>
        <li>Amazon: Convert orders code from Reports API to Orders API.</li>
        <li>Shopify: introduce support for sold channel min/max stock set when using <code>total_stock</code> as fulfillment node.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Amazon: fix issue with duplicate orders across accounts.</li>
        <li>Amazon: fix issue with missing <code>ship-by-latest</code> in edge cases.</li>
        <li>eBay: fix issue with <tt>price</tt> not sending on <code>relist</code>.</li>
        <li>BigCommerce: fix issue with errors not surfacing.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.229-bigcommerce-errors.png' alt='BigCommerce errors' />
        </li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.228',
    date: 'January 6, 2023',
    title: 'eBay Upgrade + Amazon/Facebook fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>eBay: introduce digital signatures for all <code>GetAccount</code> requests.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Orders UI: fix issue with Instagram orders not showing on Facebook tab.</li>
        <li>Amazon: fix issue with auth not showing active for SP-API only authorizations.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.227',
    date: 'December 30, 2022',
    title: 'Shopify + Amazon Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Amazon: migrate field mapping to new interface.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.227-amazon-field-mapping.png' alt='Amazon Field Mapping' />
        </li>
        <li>Shopify: introduce support for <code>shopifydescription</code> with fall back to <code>longdescription</code> and do not map to <code>description_html</code> by default.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Facebook: fix issue with tracking not processing for Instagram orders.</li>
        <li>Automation Engine: fix issue with <tt>parameters</tt> not saving for some settings.</li>
        <li>Logs: fix issue with large <code>request response</code> payloads missing from entries.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.226',
    date: 'December 23, 2022',
    title: 'Shopify + New Automations',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Shopify: authorizations re-enabled.</li>
        <li>Automations: Torqued Distribution introduce product and order integrations.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.226-torqued-distribution-integrations.png' alt='Torqued Distribution integrations' />
        </li>
        <li>Automations: introduce a shared generic purchase order integration.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.226-automation-purchase-orders.png' alt='Automated Purchase Orders' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Bulk: fix issue with bulk fitment files not completing.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.225',
    date: 'December 16, 2022',
    title: 'Automation Engine + Channel Upgrades, Throttling Launched',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Automation Engine: When performing <code>vendor_actions</code> or <code>suredone_actions</code> on multi-objects (ie order items, shipments, refunds from now now), if the search matches against the specific item OR the base order, then apply the actions to that item.</li>
        <li>AutoParts4Less: introduce support for <code>item_specific</code>.</li>
        <li>AutoParts4Less: introduce Listing Disable integration.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.225-autoparts4less-disable-listings.png' alt='AutoParts4Less Disable Listings' />
        </li>
        <li>Amazon: migrate auth and settings to new interface.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.225-amazon-settings-ui.png' alt='Amazon new interface' />
        </li>
        <li>eBay: introduce more logging for <tt>eBay Picture Services</tt> errors.</li>
        <li>SkuVault: introduce <code>skuvaultlocationinventory</code> field with object of inventory by location.</li>
        <li>SkuVault: introduce field mapping for which field to sync inventory to.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.225-skuvault-inventory-sync-field.png' alt='SkuVault inventory sync field mapping' />
        </li>
        <li>API: <tt>throttling</tt> released, please review <a href='https://www.suredone.com/guides/api/' target='_blank' rel='noopener noreferrer'>API Docs</a> for more info.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.224',
    date: 'December 9, 2022',
    title: 'Amazon + BigCommerce + eBay + Orders + Fitment Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>BigCommerce: add support for sending <code>mpn</code>.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.224-bigcommerce-mpn.png' alt='BigCommerce MPN' />
        </li>
        <li>eBay: fitment listings send <tt>Trim</tt> and <tt>Engine</tt> with values of <code>Base</code>.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.224-ebay-fitment-base.png' alt='eBay fitment base trim engine' />
        </li>
        <li>Amazon: set <code>ShippingMethod</code> when setting <code>shipservice</code> for <code>OnTrac</code> shipments.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.224-amazon-ontrac-ground.png' alt='Amazon OnTrac Ground' />
        </li>
        <li>Amazon: use carrier <code>OnTrac</code> for <code>Lasership</code> shipments.</li>
        <li>Automation Engine: support setting <code>order.items.source</code> on update order using <code>sold</code>.</li>
        <li>Orders: support <code>sold</code> request for updated order items.</li>
        <li>Fitment: storefront module filters results where price less than 0.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Logs UI: Fixed issue with missing sub filter options when selecting indexed fields including <code>identifier, context, integration, requestId</code> initially.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.223',
    date: 'December 2, 2022',
    title: 'Facebook + Amazon + BigCommerce Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Facebook: Upgraded to Graph API v15.0.</li>
        <li>Amazon: migrate backend feed processing of products to SP-API per mandates.</li>
        <li>BigCommerce: introduce support for <tt>skip stock</tt> and <tt>skip price</tt>.</li>
        <li>BigCommerce: introduce ability to modify <code>is_visible</code>.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.223-bigcommerce-is-visible.png' alt='BigCommerce Is Visible' />
        </li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.222',
    date: 'November 25, 2022',
    title: 'Channel Upgrades + Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>BigCommerce: automatically import latest category tree upon hitting the settings UI.</li>
        <li>Storefront: introduce support for cart product <code>custom_option</code> for offering customizations, will save and append to order <code>comments</code>.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.222-storefront-custom-options.png' alt='Storefront Cart Custom Options' />
        </li>
        <li>AutoParts4Less: introduce support for <tt>minstock maxstock</tt>.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.222-autoparts4less-min-max-stock.png' alt='AutoParts4Less Min Max Stock' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>BigCommerce: fix issue with importing variation images as broken urls.</li>
        <li>Orders UI: fix issue with filters not returning results for Amazon orders.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.221',
    date: 'November 17, 2022',
    title: 'eBay, Walmart + Fitment Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Amazon: introduce new auth mechanism.</li>
        <li>eBay: Fitment send <tt>Engine</tt> regardless if <tt>Trim</tt> is <code>Base</code>.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.221-ebay-fitment.png' alt='eBay Fitment Engine Base' />
        </li>
        <li>UI Product Editor: Main action button now says <tt>Relist/Publish Item</tt> instead of <tt>Retry Item</tt>.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.221-product-editor-relist.png' alt='Product Editor UI Relist/Publish' />
        </li>
        <li>Fitment: introduce category filters for the SureDone storefront.</li>
        <li>myFitment: integration updated to use SureDone SFTP credentials.</li>
        <li>BigCommerce: automatically import latest category tree upon hitting the settings UI.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Walmart: fix issue with processing product features supporting multiple values.</li>
        <li>eBay: fix issue with sending discontinued fitment <code>EPID</code>s.</li>
        <li>eBay: fix issue with revising listings with empty fitment.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.221-ebay-fitment-empty.png' alt='eBay Fitment Empty' />
        </li>
        <li>SkuVault: fix issue with <code>syncOnlineSales</code> when attempting to use <tt>manual orders</tt>.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.221-skuvault-manual-orders.png' alt='SkuVault Manual Orders' />
        </li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.220',
    date: 'November 11, 2022',
    title: 'Fitment + Shopify Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Fitment: introduce category fitment filters based on <tt>my garage</tt>.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.220-fitment-category-subfilters.png' alt='Fitment Category Subfilters' />
        </li>
        <li>Fitment: introduce custom facets editor.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.220-fitment-facets-ui.png' alt='Fitment Facets UI' />
        </li>
        <li>Shopify: order import <code>shippingservice</code> as <code>shipping_lines.title</code> instead of <code>shipping_lines.source</code>.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.220-shopify-import-shipping-service.png' alt='Shopify Shipping Service' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>UI Templates: fix issue with editing files ending in <code>.html</code>.</li>
        <li>Shopify: Fix issue to check the <code>order_use_prefix_suffix</code> setting to properly match up orders to fulfill.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.219',
    date: 'November 4, 2022',
    title: 'Shipping Upgrades + Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Shipping: format <code>GSO</code> carrier as <code>GLS</code>.</li>
        <li>Amazon: upgrade ship carrier matching logic to support wider variety of conventions.</li>
        <li>Fitment: introduce advanced instructions for customizing <a href='https://app.suredone.com/fitment-advance-instructions/bigcommerce' target='_blank' rel='noopener noreferrer'>BigCommerce</a> and <a href='https://app.suredone.com/fitment-advance-instructions/shopify' target='_blank' rel='noopener noreferrer'>Shopify</a> modules.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Amazon: fix issue where ASINs were not importing for new products.</li>
        <li>UI Dashboard: fix issue with graphs showing spike totals.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.219-dashboard-totals.png' alt='UI Dashboard Totals' />
        </li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.218',
    date: 'October 28, 2022',
    title: 'Automation Engine Upgrades + Channel Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Automation Engine: catch <tt>HTTP 401 Unauthorized</tt> and refresh auth tokens at runtime when using <code>connection.auth</code> or <code>connection.oauth</code> configs.</li>
        <li>Walmart: support new shipping carriers and adjust exact value for some existing carriers per their <a href='https://developer.walmart.com/api/us/mp/orders#operation/shippingUpdates' target='_blank' rel='noopener noreferrer'>API docs</a>.</li>
        <li>eBay: expand list of supported shipping carriers for order fulfillment.</li>
        <li>Magento2: import images into <code>media1 ... mediax</code> fields.</li>
        <li>Google: update taxonomy to version <code>2021-09-21</code>.</li>
        <li>API docs: include information regarding <tt>throttling</tt> being release on 2022-12-09.</li>
        <li>Fitment: enable product page fitment table and selected vehicle fits.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.218-fitment-product-fits-fitment-table.png' alt='Fitment Product Page Fits and Vehicle Table' />
        </li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.217',
    date: 'October 21, 2022',
    title: 'Automation Engine Upgrades + Channel Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Automation Engine: introduce <code>connection.auth</code> config that will store auth token and access it elsewhere in the config via <code>auth_value</code>.</li>
        <li>Automations: AutoParts4Less integration updated order import cancellations.</li>
        <li>API: introduce throttle limits in testing, notifications to be sent out to user base before production rollout.</li>
        <li>Fitment: change YMM and facets result URLs with query params instead of json payload.</li>
        <li>UI: enable sign out of all users on password reset or on request.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.217-ui-global-signout.png' alt='UI Global Signout' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Google: fix issue with <code>start</code> returning errors when channel is not authorized.</li>
        <li>Walmart: fix issue with <code>start</code> returning errors when channel is not authorized.</li>
        <li>Walmart: fix issue with sent feeds not being logged with related <tt>SKU</tt>.</li>
        <li>eBay: fix issue with multi item orders incorrectly calculating <code>shippingtotal</code> for orders where checkout is not complete.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.216',
    date: 'October 14, 2022',
    title: 'Automation Engine Upgrades + eBay/Amazon Improvements',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Automation Engine: introduce <code>search_limit</code> to config. Using this with <code>payload_multi false</code>, the entire automation will only process up to that limit of products/orders from the database to help with integrations that require cycling through whole catalogs one by one. Using this with <code>payload_multi true</code>, the limit will instead be used to split the template into chunks of that limit for integrations that have a limit of bulk calls per feed.</li>
        <li>eBay: retry when encountering error <code>21916720</code> and remove <code>BrandMPN</code> container.</li>
        <li>Fitment: introduce custom <tt>facets</tt>.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Automation Engine: fix validation on twig templates when the templates contain parameter values.</li>
        <li>Amazon: fix regression with migration to SP-APIs affecting saving <code>ASIN</code> for newly listed products.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.215',
    date: 'October 7, 2022',
    title: 'Orders API and Storefront Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Bulk: include download link for <tt>Fitment</tt> template.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.215-bulk-fitment-template.png' alt='Bulk Fitment Template' />
        </li>
        <li>Orders: support <code>sold</code> request for updated orders that include new order items.</li>
        <li>Storefront: allow for page urls to have infinite sub directories, supported by adding <code>+</code> suffix to page <code>name</code>.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.214',
    date: 'September 30, 2022',
    title: 'Fitment, Kits and Channel Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Fitment: dropdown options sorted alphabetically.</li>
        <li>Walmart: update product management from their v3.2 to v4.6 specification.</li>
        <li>eBay: do not send <code>BrandMPN</code> container when sending fitment to Motors site.</li>
        <li>Shopify: order introduce setting <code>order_use_prefix_suffix</code> to import order numbers with optional prefix and suffix.</li>
        <li>Kits: introduce <code>iskit_calculated_msrp</code> to dynamically calculate kit <code>msrp</code>.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Template Editor: fix issue with cache not clearing after making modifications.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.213',
    date: 'September 23, 2022',
    title: 'BigCommerce, Amazon and Fitment Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>BigCommerce: order shipments support <code>shipping_provider</code> and <code>tracking_carrier</code>.</li>
        <li>Fitment: support dynamic category facet results.</li>
        <li>Amazon: migrate remaining product imports to new SP-API.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Orders: fix issue with <tt>items, shipments, refunds</tt> count searches, for example <code>items:=2</code> returning incorrect counts.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.212',
    date: 'September 16, 2022',
    title: 'Fitment + Automation Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Fitment: introduce <tt>my garage</tt> to integration for upcoming functionality.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.212-fitment-my-garage.png' alt='Fitment My Garage' />
        </li>
        <li>Automations: Motorstate introduce option for drop shipping sending <tt>Signature Required</tt>.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Usage: calculations fixed from recent infrastructure migration.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.211',
    date: 'September 9, 2022',
    title: 'Fitment App Approved + New Integrations',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Fitment: <a href='https://apps.shopify.com/fitment-parts-compatibility' target='_blank' rel='noopener noreferrer'>Shopify app</a> approved in app store.</li>
        <li>Fitment: support upload of <code>fitmenttype</code> with values <code>Universal, MML, MVL</code> to indicate which table to lookup.</li>
        <li>Etsy: update product inventory or price and images only when requested.</li>
        <li>Automations: introduce WPS Catalog Import integration.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.211-automation-western-power-sports.png' alt='WPS Catalog Import Integration' />
        </li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.210',
    date: 'September 2, 2022',
    title: 'Fitment Upgrades + New Integration',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Fitment: module support for <tt>powersports / marine</tt> parts and accessories.</li>
        <li>Fitment: module support for <tt>universal</tt> fitting parts.</li>
        <li>Fitment: launch support for SureDone storefront.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.210-fitment-storefront.png' alt='Fitment SureDone storefront' />
        </li>
        <li>Automation Engine: gather updates into single bulk file for <code>response_field_map</code>.</li>
        <li>Automations: Akeneo introduce catalog import integration.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.210-akeneo-catalog-import.png' alt='Akeneo Catalog Import' />
        </li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.209',
    date: 'August 26, 2022',
    title: 'Fitment Upgrades + API/Channel Fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Templates: editor upgrades.</li>
        <li>Fitment: module refactor for high performance.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>API Search: fix issue with searches failing using the <code>id</code> field.</li>
        <li>Exports: fix issue with certain export file names now downloading.</li>
        <li>Shopify: fix issue preventing errors from being properly logged.</li>
        <li>Walmart: fix issue with <code>action relist</code> not sending full payloads to marketplace.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.208',
    date: 'August 19, 2022',
    title: 'New Template Editor + Logs Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Templates: new wysiwyg manager and editor launched.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.208-template-manager.png' alt='Template Manager' />
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.208-template-editor.png' alt='Template Editor' />
        </li>
        <li>Logs: complete performance upgrades, migrated <code>id</code> to new format, API sort disabled.</li>
        <li>Automation Engine: When importing a bulk file based on a product export response, always mark <code>syncskip: true</code>.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Amazon: fix regression with canceled orders restocking inventory.</li>
        <li>Automation Engine: Fix issue when using <code>sum</code> (or subtract, multiply, etc.) on a field setting the value to the field name.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.207',
    date: 'August 11, 2022',
    title: 'Amazon + Report + Fitment Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Amazon: converted orders to new SP-API.</li>
        <li>Amazon: disabled legacy functionality for <code>action end</code> setting stock 0.</li>
        <li>Reports: introduce <tt>Unsold SKUs</tt>.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.207-reports-unsold-skus.png' alt='Reports Unsold SKUs' />
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.207-reports-unsold-skus-never-sold.png' alt='Reports Unsold SKUs Never Sold' />
        </li>
        <li>Fitment: refactor module for better performance and extensibility for more YMM types.</li>
        <li>Logs: internal upgrade migrating data to new store.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Product editor: fix issue with eBay <tt>global shipping</tt> not showing set value.</li>
        <li>API Search: fix issue not comparing dates set as <code>0000-00-00</code>.</li>
        <li>Automations: Transamerican fix issue with orders not being <code>REJECTED</code>.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.206',
    date: 'August 5, 2022',
    title: 'Report + Automation Engine Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Reports: <tt>Sales per SKU by Channel</tt> and <tt>Sales per SKU</tt> updated to include <code>source</code>.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.206-reports-source.png' alt='Reports Sales By SKU Source' />
        </li>
        <li>Automation Engine: Automatically handle next tokens returning arrays when paginating.</li>
        <li>Logs: internal upgrade migrating data to new store.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>eBay: fix issue specifics missing for certain categories in the UI.</li>
        <li>Storefront: fix issue with default free shipping charging when fedex home vs fedex ground.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.205',
    date: 'July 29, 2022',
    title: 'Fitment + Storefront Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Fitment: module upgrades.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Storefront: fix issue with custom <tt>Recaptcha</tt> not working on account forgot password.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.204',
    date: 'July 22, 2022',
    title: 'Etsy + New Integrations',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Etsy: retry images that when quota limit errors are encountered.</li>
        <li>Automations: LightspeedPOS introduce inventory/sku import and order export integrations.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.204-automation-lightspeedpos.png' alt='Lightspeed POS integration' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Etsy: fix issue with import retries failing.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.203',
    date: 'July 15, 2022',
    title: 'Reports + Automation Engine + New Integrations',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Reports: introduce Sales per SKU report.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.203-reports-sales-by-sku.png' alt='Reports Sales By SKU' />
        </li>
        <li>Fitment: improve loader processing for beta users.</li>
        <li>MaaS API: introduce endpoint to migrate user with creation.</li>
        <li>Automation Engine: Allow for <code>connection.payload: TEMPLATE</code>.</li>
        <li>Automations: AutoParts4Less integration updated with new APIs.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.177-autoparts4less-integrations.png' alt='AutoParts4Less integration Upgrade' />
        </li>
        <li>Automations: introduce integration with Coupang marketplace.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.203-automation-coupang.png' alt='Coupang integration' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>eBay: fix issue with <tt>Item Specifics</tt> sending on revise of non item specifics data.</li>
        <li>Automation Engine: fix issue to enable <code>TEMPLATE</code> when setting <code>payload_multi: true</code>.</li>
        <li>Automation Engine: fix issue and handle edge case in pagination where an API returns a valid <code>next token</code> key but its value is empty.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.202',
    date: 'July 8, 2022',
    title: 'Automation Integration Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Automations: Turn14 introduce <tt>reject backorder</tt> option to reject orders that are on backorder.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.202-turn14-backorder.png' alt='Turn14 backorder' />
        </li>
        <li>Automations: LightspeedEVO introduce inventory import integration.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.202-lightspeed-evo.png' alt='LightspeedEVO integration' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Inventory Labels: fix issue with barcodes not showing on custom layouts.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.201',
    date: 'July 1, 2022',
    title: 'BigCommerce/Shopify Fitment + Streetpricer Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>BigCommerce: fitment compatibility app approved in app store.</li>
        <li>Shopify: fitment compatibility app upgrades.</li>
        <li>StreetPricer: integration and partnership added to SureDone, launched in <tt>beta</tt>.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.201-streetpricer-integration.png' alt='Streetpricer repricer integration' />
        </li>
        <li>Automation Engine: introduce config for <code>connection.pagination.start_page</code>.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.200',
    date: 'June 24, 2022',
    title: 'Automation Engine + Storefront Fitment Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Automation Engine: performance upgrades to improve processing time.</li>
        <li>Storefront: fitment compatibility launched.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.200-storefront-fitment.png' alt='Storefront fitment search' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Product editor: fix issue with large multi image requests failing to save.</li>
        <li>eBay: fix issue with legacy settings interface not loading.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.199',
    date: 'June 17, 2022',
    title: 'Product UI + Bulk Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>UI Product Editor: Custom Fields navigation now show field groups.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.199-product-editor-field-groups.png' alt='Product editor field groups' />
        </li>
        <li>Ship Tracking Templates: updated to better parse shipping carriers.</li>
        <li>Bulk: delay database updates upon creation of custom fields.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Google: fix issue with <code>ShippingCountry</code> being sent incorrectly in payloads.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.198',
    date: 'June 10, 2022',
    title: 'Core Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Core: upgrade to latest version of PHP8 for security and performance.</li>
        <li>Templates: upgrade to <a href='https://twig.symfony.com/doc/3.x/' target='_blank' rel='noopener noreferrer'>Twig 3.4</a>.</li>
        <li>Etsy: better error handling for images.</li>
        <li>Channels UI: Reauthorization enabled for Google, Facebook and Walmart.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Shopify: fix issue with image uploads exceeding the <a href='https://help.shopify.com/en/manual/products/product-media/add-media' target='_blank' rel='noopener noreferrer'>documented limit</a>.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.197',
    date: 'June 4, 2022',
    title: 'BigCommerce + Shopify Fitment',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>BigCommerce: fitment compatibility app launched.</li>
        <li>Shopify: fitment compatibility app launched.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.196',
    date: 'May 27, 2022',
    title: 'Shopify + Amazon + eBay Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Shopify: improve throttle handling.</li>
        <li>Amazon: expand <tt>ASIN</tt> validation.</li>
        <li>eBay: support <code>ebaydispatch DispatchTimeMax</code> values of 30 and 40 days for handling times.</li>
        <li>Automations: Updated interface to show and empty state and better discovery.</li>
        <li>Reports: Filter by <code>brand</code> on the <tt>Per Order Profit Margins</tt> report.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.195',
    date: 'May 20, 2022',
    title: 'Walmart + API + Automation upgrades and fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Walmart: better logging of <code>request / response</code>.</li>
        <li>Automation: Turn14 integration no longer sends <code>signature_confirmation</code>.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>API: fix issue with orders permissions allowing updates with view only grants.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.194',
    date: 'May 13, 2022',
    title: 'Internal upgrades and fixes',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Fitment: Launched ability to enable/disable fitment for users.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.193',
    date: 'May 6, 2022',
    title: 'Billing UI + Channels + Reports + Shipments Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>eBay: Imports log <code>feed_task_id</code>.</li>
        <li>Google: Return <a href='https://developers.google.com/shopping-content/guides/product-issues?hl=en' target='_blank' rel='noopener noreferrer'>product issues</a> as part of status check as log <code>warnings</code>.</li>
        <li>Shopify: refactor fulfillment logic to better support combinations of line item, exploded kits and whole order tracking.</li>
        <li>Shipments:
          <ul>
            <li>Imports of shipments with duplicate <code>shiptracking</code> will now attempt to match to existing shipment instead of creating duplicate.</li>
            <li>Normalize <code>shipitems</code> to always have <code>sku quantity fulfilled</code> keys.</li>
          </ul>
        </li>
        <li>Reports: Added <code>Cost</code> and <code>Total Item Cost</code> to the <code>Restock Forecast</code> report.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.193-Reports.png' alt='Reports' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Products UI:
          <ul>
            <li>Walmart: Fixed issue with Walmart API suddenly changing their return format.</li>
            <li>Amazon: Fixed issue with line item tracking for multi item orders generating fulfillment errors with mixing fulfillment types.</li>
            <li>Shopify: Fixed issue with imports missing inventory data.</li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.192',
    date: 'April 29, 2022',
    title: 'ebay + Shopify Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>eBay: Replace LMS with new Feed API calls for product imports.</li>
        <li>Shopify: Closed POS orders now sync related inventory.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Products UI:
          <ul>
            <li>Fixed issue with some requests not saving empty values.</li>
            <li>Fixed issue with custom fields not showing labels for fields without them.
              <br />
              <img src='https://assets.suredone.com/multichannel-platform/updates/v3.192-Products-UI.png' alt='Products UI' />
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.191',
    date: 'April 22, 2022',
    title: 'Orders UI Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Orders: Introduce order item kit import components setting for exploding kit items into component items on order creation
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.188-Orders.png' alt='Orders' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Automation Engine: Fixed error blocking SFTP downloads of compressed files.</li>
        <li>Amazon: fix issue with ship tracking logs missing data.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.190',
    date: 'April 15, 2022',
    title: 'UI + eBay Fixes',
    content:
  <ul>
    <li><strong>Fixes</strong>
      <ul>
        <li>eBay: fix issue with default profiles not loading on product creation.</li>
        <li>Product editor UI: fix issue with recent update only changes not updating specific fields.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.189',
    date: 'April 8, 2022',
    title: 'Automations + Storefront + Product Editor UI Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Automation Engine: Allow for use of <code>remapField</code> for order items/shipments.</li>
        <li>Storefront: PayPal checkout allows for inputting phone number if empty from PayPal.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.189-Storefront.png' alt='Storefront' />
        </li>
        <li>Product editor UI: Update only changes to product details.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.188',
    date: 'April 1, 2022',
    title: 'Automations + Walmart + Custom Field Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Automation UI: Integrations may now be deleted.</li>
        <li>Walmart: support for line item shipments using <code>shipsku</code>.</li>
        <li>Automation: Parts Unlimited updated to newest API version.</li>
        <li>Custom Field: A notification is shown once user saves a custom field
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.188-Custom-Fields.png' alt='Custom Fields' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Bulk UI: Fixed bug where when you cancel a job, all jobs below that list also show as canceled.</li>
        <li>Product UI: Fixed issue with BigCommerce property values not showing.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.187',
    date: 'March 25, 2022',
    title: 'Shopify + Walmart UI Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Shopify:
          <ul>
            <li>Html tags are now stripped from <code>metadescription</code>.</li>
            <li>Support import of order <code>tips</code> as <code>internalnotes</code>.
              <br />
              <img src='https://assets.suredone.com/multichannel-platform/updates/v3.187-Shopify.png' alt='Shopify' />
            </li>
          </ul>
        </li>
        <li>Walmart: Accommodate new order date formats being sent via the API.</li>
        <li>Amazon: Support <code>UnitCount</code> and <code>PPUCountType</code> in Universal Product Fields.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Search: Fixed issue with <code>OR</code> search of exact fields not working.</li>
        <li>eBay: Fixed issue with <code>maxstock</code> only updates causing revise inventory errors.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.186',
    date: 'March 18, 2022',
    title: 'Reports UI Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Reports UI: Enabled filter by <code>brand</code> in the Restock Forecast reports.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.186-Reports.png' alt='Facebook' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Channel Imports: Fixed issue with stale import files being returned.</li>
        <li>Products UI: Fixed issue with exports of single product not working.</li>
        <li>User Fields: Fixed issue with values not saving correctly and breaking product editor.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.185',
    date: 'March 11, 2022',
    title: 'Logs UI + Facebook Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Logs UI: Default view now shows columns <code>identifier, result, action, context, createdAt, type, method, operation</code> and <code>integration</code>.</li>
        <li>Facebook: Support for <code>shipping_profile_reference_id</code>.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.185-Facebook.png' alt='Facebook' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Facebook: Fixed issue with orders pagination.</li>
        <li>Shopify: Fixed internal <code>type</code> representation of metafields to fix error returned from the API.</li>
        <li>eBay: Fixed issue with importing Payment Policies.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.184',
    date: 'March 4, 2022',
    title: 'Storefront + Automations Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Storefront: Checkout tax by shipping address.</li>
        <li>Automations: Tucker integration upgraded to support multi item orders.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.183',
    date: 'Feburary 25, 2022',
    title: 'Storefront Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Storefront: Default template includes a README for template files.</li>
        <li>Billing UI: Prevents browser input suggestions on billing checkout modal.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.182',
    date: 'Feburary 18, 2022',
    title: 'Channels + Automation Engine + Bulk Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Shopify:
          <ul>
            <li>Updated Shopify API version from <code>2020-10</code> to <code>2022-01</code>.</li>
            <li>Updated metafields property for new API.</li>
          </ul>
        </li>
        <li>Automation Engine: Now supports the use of <code>ADDRESS</code> in <code>template</code>.</li>
        <li>Facebook: Upgraded to Graph API v12.0.</li>
        <li>Bulk Orders: Support <code>shipsku, shipquantity, shipfulfilled, shipitems</code> fields.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Automation Engine: Fixed issue with <code>vendor_actions</code> checks.</li>
        <li>Shopify: Fixed issue with <code>voided</code> orders not importing as payment <code>INCOMPLETE</code>.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.181',
    date: 'Feburary 11, 2022',
    title: 'Shopify + Automations Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Shopify: Now assign the first unique image of a variant on SureDone (i.e. an image of a variation that is not contained in any other variations) to the Shopify variant, as opposed to the first image overall.</li>
        <li>Automation Engine: Introduced <code>vendor_actions.ignoreOrder</code> to scan all order items/shipments and ignore the entire order if the search matches on any of the rules.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Shopify: fix import missing <code>shopifypath shopifyurl</code> and formatting <code>shopifytags</code>.</li>
        <li>Automation Engine: Fix issue with field in <code>vendor_actions.search</code> not being a valid item/shipment field and still not be completely ignored and treated as a non-match when running actions on item/shipment.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.180',
    date: 'February 4, 2022',
    title: 'Etsy + Bulk + Automations Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Etsy: Updated <code>when_made 2020_2022</code> value.</li>
        <li>Bulk Exports UI: Selector has been increased to avoid side scrolling.</li>
        <li>Automation Engine: Now adds products to bulk file based on diffs of all attached products, not just one.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.179',
    date: 'January 29, 2022',
    title: 'Bulk + Orders + Automations + Channel Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Core: Improved performance across system with kit product by fixing inefficiency with always pulling kit data and checking kit items when not requested.</li>
        <li>Orders: introduce <code>shipments.shipdetails.items.fulfilled</code> to note when shipment has been sent to channel.</li>
        <li>eBay: Update API version to <code>1239</code>.</li>
        <li>Facebook:
          <ul>
            <li>Upgraded to Graph API v12.0.</li>
            <li>Limit <code>facebooktitle</code> to 150 characters.</li>
          </ul>
        </li>
        <li>Shopify: Support for line item shipments using <code>shipsku</code> and optionally <code>shipquantity</code>.</li>
        <li>Automations: Introduced new kit explode integration that also imports components and sets each order item component stock source.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Bulk: Fix issue with legacy order headers returned in file templates.</li>
        <li>Exports: Fixed issue with exports taking a long time on accounts with may <code>kits</code>.</li>
        <li>Amazon: fix issue with orders missing <code>meta.raw.order</code> payloads.</li>
        <li>Shopify: Fixed issue with sold stock sync issues using <code>total_stock</code> as fulfillment node.</li>
        <li>Shopify: fix issue with sending images smaller than full size.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.178',
    date: 'January 21, 2022',
    title: 'Logs UI',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Bulk: upgrade system to avoid re-enqueueing complete jobs.</li>
        <li>Imports: UI now shows request date and file name.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.178-imports.png' alt='Channel Imports' />
        </li>
        <li>Orders:
          <ul>
            <li>Import kit component <code>source</code> as <code>itemdetails.product.kitlinks.i.2</code>. Will attempt to stock check component based on <code>quantity * kitlink.quantity</code>.</li>
            <li>API create/patch introduce <code>set_product</code> parameter to attempt setting <code>itemdetails.product</code> on creation of order items.</li>
            <li>Reduce the number of product fields saved into <code>items.itemdetails.product</code>.</li>
            <li>API created orders will only set item <code>source</code> with use of <code>sold</code> to sync inventory.</li>
          </ul>
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Logs UI: Fix issue with search not working with filters including characters like <code>+</code>.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.177',
    date: 'January 14th, 2022',
    title: 'Automation + Etsy Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Automation: Launched integration with Yahoo! Verizon Small Business.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.177-yahoo-small-business-integrations.png' alt='Yahoo! Small Business integrations' />
        </li>
        <li>Automation: Launched integration with AutoParts4Less.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.177-autoparts4less-integrations.png' alt='AutoParts4Less integrations' />
        </li>
        <li>Etsy: improved variation listing creation process.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.176',
    date: 'January 7th, 2022',
    title: 'Automation + Shopify Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Automation: SDC integration updated with new 7.0 specs.</li>
        <li>Shopify: Increased number of workers to handle higher volume sellers.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>eBay:
          <ul>
            <li>Fixed issue with variation price updates updating <code>Quantity</code> and not respecting <code>min/max stock</code> buffers.</li>
            <li>Fixed issue with combined orders not properly being <code>archived</code>.</li>
          </ul>
        </li>
        <li>Magento2: Fixed issue with empty media payloads sent with image url is invalid causing base64 encoded data to be blank.</li>
        <li>Keystone Automation:
          <ul>
            <li>Fixed issue with Keystone drop shipping POs sending for multiple order items.</li>
            <li>Fixed issue with kits explode integration sometimes not exploding all kits.</li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.175',
    date: 'December 31, 2021',
    title: 'Fitment + Channel Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Fitment: Private alpha preview launched to select accounts.</li>
        <li>Walmart: Better shipping <code>methodCode</code> handling.</li>
        <li>eBay: UI removes deprecated <code>ebayautorelist</code> functionality.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.175-eBay.png' alt='eBay' />
        </li>
        <li>Magento2: Better handling of images using MIME type from the raw image data.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Etsy: Fixed issue with variations not listing.</li>
        <li>Shopify: Fixed issue with <code>price</code> missing from imports.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.174',
    date: 'December 24, 2021',
    title: 'Automation + Channel upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Automations:
          <ul>
            <li>Automatic Distributors tracking import mapping updated.</li>
            <li>Allow <code>vendor_actions.setValue</code> to search on an item field and update a base order field if ANY of the items match the search.</li>
          </ul>
        </li>
        <li>Amazon: Order fulfillment tracking store the API response in logs.</li>
        <li>Shopify:
          <ul>
            <li>Order unpaid will not be imported.</li>
            <li>Introduced pending sync setting to import unpaid orders.
              <br />
              <img src='https://assets.suredone.com/multichannel-platform/updates/v3.174-shopify2.png' alt='Shopify' />
            </li>
          </ul>
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>eBay: fix issue with edge case error on line item ship tracking.</li>
        <li>Shopify: fix issue for properly setting error when inventory updates fail which should trigger retries on <code>Throttled</code> errors.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.173',
    date: 'December 17, 2021',
    title: 'Channel + API + Orders UI + Settings upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Shopify:
          <ul>
            <li>Add support for <code>minstock</code> and <code>maxstock</code> as mappable fields for Shopify.</li>
            <li>Support the use of min/max stock along with <code>total_stock</code> and send it to whatever warehouse is mapped to <code>total_stock</code>.</li>
            <li>Set the default Shopify <code>inventory management</code> setting such that SureDone stock values update Shopify by default.
              <br />
              <img src='https://assets.suredone.com/multichannel-platform/updates/v3.173-Shopify.png' alt='Shopify' />
            </li>
          </ul>
        </li>
        <li>Channel UI: <code>title</code> and <code>price</code> were removed from channel mappable fields.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.173-Field-Mapping.png' alt='Field Mapping' />
        </li>
        <li>API: Support saving <code>site_currency</code>.</li>
        <li>Orders UI Shipments: carrier input now has a searchable drop down for each channel and an option to enter in text.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.173-Orders-UI.png' alt='Orders UI' />
        </li>
        <li>ShipStation: support custom fields for order details with notation <code>details.fieldName</code>.</li>
        <li>Organization Setting:
          <ul>
            <li><code>/settings/organization/accountID</code> page was created to show the Account ID to users.</li>
            <li>It's only accessible by URL, no tabs or links in the UI.
              <br />
              <img src='https://assets.suredone.com/multichannel-platform/updates/v3.173-Settings.png' alt='Settings' />
            </li>
          </ul>
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Shopify: Fix issue with setting order local date incorrectly.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.172',
    date: 'December 10, 2021',
    title: 'Channel + ShipStation upgrades + Logs UI + Automation Logs upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Amazon: Fulfillment support <code>ShippingMethod</code> for FedEx.</li>
        <li>eBay: retry on error code <code>10007</code>.</li>
        <li>Etsy: order imports <code>sku</code> now save <code>etsysku</code> instead of <code>etsylistingid</code> if not empty.</li>
        <li>BigCommerce: Introduced setting for <code>order_import_statuses</code> that takes list of <a href='https://developer.bigcommerce.com/api-reference/store-management/orders/order-status/getorderstatus' target='_blank' rel='noopener noreferrer'>order status IDs</a> separated by commas for importing orders that are not in <code>Awaiting Shipment</code> or <code>Awaiting Fulfillment</code>.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.172-Bigcommerce.png' alt='Bigcommerce' />
        </li>
        <li>ShipStation: support custom fields for order details with notation <code>details.fieldName</code>.</li>
        <li>Logs UI: A <code>Refresh</code> button was added.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.172-Logs.png' alt='Logs' />
        </li>
        <li>Automation Logs:
          <ul>
            <li>Now logs are shown newest first, oldest last.</li>
            <li>A <code>Refresh</code> button was added.
              <br />
              <img src='https://assets.suredone.com/multichannel-platform/updates/v3.172-Automations-Logs.png' alt='Automations Logs' />
            </li>
          </ul>
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Statuses: Fixed issue with statuses not showing real time.</li>
        <li>Logs: Fixed issue with logs duplicating on specific retry conditions.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.171',
    date: 'December 3, 2021',
    title: 'Orders UI + Amazon + ShipStation upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Orders UI: Now the order items interface shows the contents of order item product fields.</li>
        <li>Amazon:
          <ul>
            <li>Any <code>@unitOfMeasure</code> fields that are mapped to a custom field will take the value of the custom field instead of the static value.</li>
            <li>Updated <code>Product</code> attributes to add <code>ItemForm, NetContentCount, NetContentWeight, NetContentVolume, NetContentLength, NetContentArea</code>.</li>
            <li>Updated <code>FoodAndBeverages</code> to add all attributes from XSD.</li>
          </ul>
        </li>
        <li>ShipStation: Enable search to filter orders exported to ShipStation using query <code>q</code>. These searches are compatible with the SureDone interface and API.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.171-ShipStation.png' alt='ShipStation' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Automation Engine: Fixed issue with SFTP connections using retries.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.170',
    date: 'October 26, 2021',
    title: 'Automation Engine Fix',
    content:
  <ul>
    <li><strong>Fixes</strong>
      <ul>
        <li>Automation Engine: Fixed issue with SFTP connections using retries.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.169',
    date: 'November 19, 2021',
    title: 'Orders UI +  Automation Engine upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Orders UI: Enabled ability to delete order items and shipments.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.169-Orders-UI.png' alt='Orders UI' />
        </li>
        <li>Automation Engine:
          <ul>
            <li>Added support for <code>pointers</code> in the <code>connection</code> config for integrations that do not use standard pagination and instead ID pointers.</li>
            <li>Ignores <code>missing_item_actions</code> when integration payload is empty.</li>
          </ul>
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Saved Searches:
          <ul>
            <li>Fixed issue with saved searches not showing in V3 UI.
              <br />
              <img src='https://assets.suredone.com/multichannel-platform/updates/v3.169-Saved-Searches.png' alt='Saved searches' />
            </li>
            <li>Fixed issue with long search strings greater than 100 characters not saving properly.
              <br />
              <img src='https://assets.suredone.com/multichannel-platform/updates/v3.169-Saved-Searches-2.png' alt='Saved searches 2' />
              <img src='https://assets.suredone.com/multichannel-platform/updates/v3.169-Saved-Searches-3.png' alt='Saved searches 3' />
            </li>
          </ul>
        </li>
        <li>Kits: Fixed issue with components syncing kits not respecting skips and updating more than inventory.
          <ul>
            <li>When a component is updated, any skips applied to that component will be applied to affected kits. For example, if <code>skip all channels</code> is toggled then all related kits will skip all channels. Similarly, if only eBay is skipped, then only eBay is skipped for affected kits.</li>
            <li>Related kits will now only update one or more of the following fields on channels: inventory, price and/or cost. Price and/or cost will only update on channels if the Kit Auto Calculate Price/Cost features are enabled respectively.</li>
            <li>The above kit fields will only update if the updated component has those fields updated. For example, if only <code>title</code> is updated on the component then there will be no updates to the related kits.</li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.168',
    date: 'November 12, 2021',
    title: 'Statuses + Logs UI + Product editor + Amazon upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Statuses: Remove 5 min delay.</li>
        <li>Logs UI: Now all fields in specific log page are able to handle objects and arrays as content. </li>
        <li>Product editor: Create similar now ignores <code>ebayrelistid</code>. </li>
        <li>Amazon:
          <ul>
            <li>Added support for <code>isExpirationDatedProductAttribute</code>. </li>
            <li>Fulfillment support <code>ShippingMethod</code> for USPS. </li>
            <li>Fulfillment, set <code>ShippingMethod</code> to <code>Other</code> if not matched against list of services available in Seller Central. </li>
          </ul>
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Walmart: Fixed issue with custom field mapping for category attributes not working.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.168-Walmart-Fields.png' alt='Walmart Fields' />
        </li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.167',
    date: 'November 5, 2021',
    title: 'Automations + Walmart upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Automations: Use <code>order_update_search</code> to better process line item ship tracking imports.</li>
        <li>Orders UI: Change invoice linking from <code>order</code> to <code>oid</code>.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Walmart: Fix issue with bulk updates for <code>walmartislisted</code> not working.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.166',
    date: 'October 29, 2021',
    title: 'Automations + Channel upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Automation Engine: Add <code>vendor_actions</code> support for <code>response_field_map</code> and introduce <code>response_field_run</code>.</li>
        <li>Amazon: Introduced sync last option to not import orders older than specific date.</li>
        <li>Walmart: better parsing of <code>GROUND</code> ship service tracking.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Magento2: Fixed paginated calls.</li>
        <li>Orders: Fixed issue with marking orders <code>shippingstatus PARTIAL</code> for shipments using <code>shipsku</code> but no <code>shipquantity</code> when quantity is greater than 1.</li>
        <li>BigCommerce: Fixed issues where the <code>manage product rules</code> setting was not respected and where variation fields such as <code>price</code> could not be updated (leading to <code>resource not found error</code>).</li>
        <li>Automations UI: Fixed issue with <code>View full log</code>.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.166-Automations-logs.png' alt='Automations Logs' />
        </li>
        <li>Logs UI: <code>request</code> and <code>esponse</code> now are shown correctly when they are array of objects.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.166-Logs-UI.png' alt='Logs UI' />
        </li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.165',
    date: 'October 22, 2021',
    title: 'Amazon + Automations + Logs UI + Product Editor UI upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Amazon: Fulfillment support <code>ShippingMethod</code> for UPS.</li>
        <li>Automations:
          <ul>
            <li>Kits explode automation correctly set <code>quantity</code> for multiple kit quantity.</li>
            <li>Kits component import support order item custom field parameters.</li>
          </ul>
        </li>
        <li>Logs UI: Introduced lazy pagination to make requests
          <ul>
            <li><code>Page</code> and <code>size</code> were added to the URL to maintain the pages shareables.</li>
            <li>Page size is <code>10</code> by default.</li>
          </ul>
        </li>
        <li>Product Editor UI:
          <ul>
            <li>Required fields now have a red asterisk implemented.</li>
            <li>Skuvault <code>Supplier Info Is Active(s)</code> and <code>Supplier Info Is Primary(s)</code> now are select menus with <code>true</code> and <code>false</code> as options.
              <br />
              <img src='https://assets.suredone.com/multichannel-platform/updates/v3.165-Product-Editor.png' alt='Product Editor UI' />
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.164',
    date: 'October 15, 2021',
    title: 'Orders + Automations + eBay upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Orders: Introduced internal <code>shippingstatus PARTIAL</code>. For <code>shipments</code> submitted with a <code>shipsku</code> SureDone will now check an internal reference table of existing order items and shipments to determine if the new shipments partially fulfill an order with multiple order items. For each order item, the system will consider the item shipped with <code>itemstatus COMPLETE</code> OR if there is another shipment with <code>shipsku</code> attached. Example shipment <code>shipments.shipment.shipdetails [items: [[sku:CS192-B000L, quantity:1]]]</code>. By this logic, if there are any order items unfulfilled, SureDone will set the <code>shippingstatus PARTIAL</code>. Further, with this shipping status SureDone will send fulfillment requests for connected channels and attempt to assign line item specific shipments accordingly.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.164-Oders-Shipped.png' alt='Orders Shipped' />
        </li>
        <li>Automation Engine: Introduced <code>allow_empty_values</code> to trigger config, defaults to <code>true</code>. Set to <code>false</code> to force a retry when trigger value is empty.</li>
        <li>eBay: Category &amp; fitment compatibility tables updated to latest version.</li>
        <li>Orders UI: Update submit button replaced with <code>Update Changes</code> functionality.
          <ul>
            <li>Invoice button was restored and <code>- select action -</code> button was removed.</li>
            <li>Update button now updates only the values that were updated.
              <br />
              <img src='https://assets.suredone.com/multichannel-platform/updates/v3.162-Imports-UI.png' alt='Imports UI' />
            </li>
          </ul>
        </li>
        <li>Automations UI: New <tt>beta</tt> badge is shown for all shared integrations in beta status.</li>
        <li>Automations UI: Disclaimer of responsibility is shown when enabling shared integrations marked with a <code>disclaimer</code> flag or when enabling any custom integration.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Product Editor: Fixed eBay tooltip to mention <code>State</code> of the location of the item.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.162-Imports-UI.png' alt='Imports UI' />
        </li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.163',
    date: 'October 8, 2021',
    title: 'Bulk + Reports upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Reports: Refactored to calculation of costs for all reports to use <code>itemdetails.product.cost * quantity</code> for all order items. The reports affected are: COGS Summary, COGS Details and Per Order Profit Margins.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.163-Reports-UI.png' alt='Reports UI' />
        </li>
        <li>Reports: Added <code>brand</code> Inventory - As of Date report.</li>
        <li>Bulk: Launched new <a href='https://assets.suredone.com/guides/media/bulk/SureDone-Bulk-Headers-Sorted-2021.xlsx'>2021 bulk guide</a>.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>eBay: Fixed issue with order fee imports creating orphan order items.</li>
        <li>Walmart: Fixed issue with tracking not sending due to invalid <code>shipservice</code>.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.162',
    date: 'October 1, 2021',
    title: 'Channel + DCi + ShipStation upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Google: Charge orders after import &amp; acknowledgement but before shipment of orders that have at least 1 order item linked to a product in SureDone and has inventory <code>source</code> linked to a single stock field. See <a href='https://developers.google.com/shopping-content/guides/building-orders#charge' target='_blank' rel='noopener noreferrer'>Google Orders API docs</a> for more information. This applies to accounts with the Order Acknowledgement setting enabled.</li>
        <li>Channel Imports: <code>skipUpdate</code> functionality implemented for ignoring fields to update during import on existing products in SureDone.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.162-Imports-UI.png' alt='Imports UI' />
        </li>
        <li>DCi: Introduced <code>mapprice_override</code> to set the <code>mapprice</code> field to another field when the mapprice is 0.</li>
        <li>ShipStation: Setting a <code>custom field</code> to <code>ship-by-latest-days</code> will calculate the number of days between the date and the earliest <code>ship-by-latest</code> and set it at as a custom field value.</li>
        <li>Magneto:
          <ul>
            <li>Increase Magento2 timeouts 60s to 300s</li>
            <li>Increase Ajax timeouts 60s to 300s</li>
          </ul>
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Walmart: Fixed OAuth token not decrypting.</li>
        <li>DCi: Fixed issue with processing fields <code>caprop65message caprop56image caprop65ind</code>.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.161',
    date: 'September 24, 2021',
    title: 'DCi + Channel upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Amazon: set <code>IsExpirationnDateProduct required</code> for the <tt>Camera &amp; Photo, Food &amp; Beverages, Health, Pet Supplies and Wine/Alcohol</tt> categories.</li>
        <li>DCi: support specifying field for <code>guid/sku</code>.</li>
        <li>Walmart: support order status <code>Delivered</code>.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.160',
    date: 'September 17, 2021',
    title: 'Automations + Reports UI + Google upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Automation Engine: Added <code>pagination.ignore_errors</code> to allow 500 level errors during pagination.</li>
        <li>Reports UI:
          <ul>
            <li>Number formats is 999.99 (2 decimal places).</li>
            <li>Works if you filtering for one day.</li>
            <li>Now shows totals on bottom row
              <br />
              <img src='https://assets.suredone.com/multichannel-platform/updates/v3.160-Reports-Totals.png' alt='Totals' />
            </li>
            <li>Per Order Profit Margin: Added skus as a comma delimited
              <br />
              <img src='https://assets.suredone.com/multichannel-platform/updates/v3.160-Per-order-profit-margin.png' alt='Per order profit margin' />
            </li>
            <li>Per Order Profit Margin: Supports final & other channel specific fees in <code>order.details</code>
              <br />
              <img src='https://assets.suredone.com/multichannel-platform/updates/v3.160-Item-fees.png' alt='Item fees' />
            </li>
            <li>Sales Tax Line Item Report: New report introduced. Same as Sales Tax report except is laid out and calculated by order item instead of order.
              <br />
              <img src='https://assets.suredone.com/multichannel-platform/updates/v3.160-Sales-tax-line-item.png' alt='Sales Tax Line Item' />
            </li>
            <li>Sales Tax Line Item Report: Added ability to filter by state
              <br />
              <img src='https://assets.suredone.com/multichannel-platform/updates/v3.160-Sales-tax-line-item-2.png' alt='Sales Tax Line Item 2' />
            </li>
          </ul>
        </li>
        <li>Google: updated <code>Destinations</code> list to <a href='https://support.google.com/merchants/answer/7501026' target='_blank' rel='noopener noreferrer'>latest version</a>.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Bulk UI: Fixed issue with <code>cancellation</code> status not showing correctly.</li>
        <li>UI Products List: Fixed issue edge case with sub users not showing customized interfaces.</li>
        <li>Status UI: Fixed issue with occasional double search bars.</li>
        <li>eBay: Fixed issue with instances not saving <code>itemspecifics</code> API data.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.159',
    date: 'September 10, 2021',
    title: 'Facebook + ShipStation + ShipWorks upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Facebook: Download image urls during product import.</li>
        <li>ShipStation: Orders with status <code>REJECTED</code> will be marked on hold.</li>
        <li>ShipWorks: Orders with status <code>REJECTED</code> will be sent and include an internal note <code>Order Status REJECTED</code>.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Invoices: Fixed issue with 2 page prints.</li>
        <li>Bulk: Fixed issue with <code>kitlinks</code> not saving with edge case sensitivity.</li>
        <li>ShipWorks: Orders with status <code>REJECTED</code> will be sent and include an internal note <code>Order Status REJECTED</code>.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.158',
    date: 'September 3, 2021',
    title: 'Walmart + Storefront + Automations + UI upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Walmart:
          <ul>
            <li>Migrated settings, authorization and imports to new interface
              <br />
              <img src='https://assets.suredone.com/multichannel-platform/updates/v3.158-Walmart.png' alt='walmart' />
            </li>
            <li>Field mappings + Walmart specific fields migrated to new interface.
              <br />
              <img src='https://assets.suredone.com/multichannel-platform/updates/v3.158-Walmart-manage-fields.png' alt='manage fields' />
              <img src='https://assets.suredone.com/multichannel-platform/updates/v3.158-Walmart-specific-fields.png' alt='sepcific fields' />
            </li>
          </ul>
        </li>
        <li>Google: Add support for product dimension fields <code>productWeight, productLength, productWidth, productHeight</code>.</li>
        <li>Storefront: Introduced support for Stripe <code>Statement Descriptor</code>.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.158-statement.png' alt='Storefront Statement' />
        </li>
        <li>Automations: Introduced Automatic Distributors catalog import.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.158-Automations.png' alt='Automations' />
        </li>
        <li>Order Editor UI: Introduce <code>Select action</code> button, which has <code>Invoice</code> and <code>Update changes</code> options. Update changes will only fields that are changed on the page instead of the default behavior of saving everything o n the screen.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.158-Order-Editor.png' alt='Oder editor' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Product Editor UI: Fixed issue eBay schedule time toggle not staying on for scheduled listings.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.158-Product-editor.png' alt='Product editor' />
        </li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.157',
    date: 'August 27, 2021',
    title: 'Channel + Bulk API + Automations upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>eBay: Introduced support for Marketplace deletion notifications.</li>
        <li>DCI: Introduced mode to skip creating new products.</li>
        <li>Bulk API: Introduced <code>skipupdate</code> parameter to ignore fields to be updated with action <code>edit relist</code>. The purpose is to enable files to be processed with multiple actions without overwriting data.</li>
        <li>Automations: Added drop shipping support for Automatic Distributors.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.157-Automations-dropshipping.png' alt='Automations' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>ShipStation: Fixed issue with grams calculation.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.156',
    date: 'August 20, 2021',
    title: 'eBay + Automations upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>eBay: Improved functionality with setting <code>skipchecks on</code> to always send Trading API call <code><a href='https://developer.ebay.com/Devzone/XML/docs/Reference/eBay/AddFixedPriceItem.html' rel='noopener noreferrer'>AddFixedPriceItem</a></code> when creating new listings.</li>
        <li>eBay: Imports now include item specific SKU as <code>usersku</code> field.</li>
        <li>Automations: All drop shipping integrations now run every 5 mins.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Bulk: Fixed issue with UI imports not loading due to file size.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.155',
    date: 'August 13, 2021',
    title: 'Channel + Automations + UI upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Amazon: Avoid creating dummy shipments when marking shipped from order report updates.</li>
        <li>Facebook: Upgraded to Graph API v11.0.</li>
        <li>Automations: V-Twin Inventory Updates added.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.155-Inventory-Update.png' alt='Inventory Update' />
        </li>
        <li>UI Logs: Logs detail page shows formatted XML and JSON in <code>request</code> and <code>response</code>.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.155-Logs-UI.png' alt='Log UI' />
        </li>
        <li>Orders UI: Introduced copy button to billing and shipping addresses.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.155-Orders-UI.png' alt='Orders UI' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Usage: Fixed issue with <code>export</code> limits being hit after upgrading.</li>
        <li>Exports: Fixed issue with edge case of missing products for older accounts.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.154',
    date: 'August 6, 2021',
    title: 'SkuVault + Walmart + UI upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>SkuVault: Added default settings for <code>supplier info is active/primary</code>.</li>
        <li>Walmart: Import shipping templates and fulfillment centers upon initial authorization.</li>
        <li>UI Product Editor: Added support for shipping metric units.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.154-Weight-setting-1.png' alt='Weight 1' />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.154-Weight-setting-2.png' alt='Weight 2' />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.154-Weight-setting-3.png' alt='Weight 3' />
        </li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.153',
    date: 'July 30, 2021',
    title: 'Amazon + Order UI upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Amazon: Introduced support for more fulfillment carriers based on the <a href='https://images-na.ssl-images-amazon.com/images/G/01/rainier/help/xsd/release_1_9/amzn-base._TTH_.xsd' target='_blank' rel='noopener noreferrer'>XSD</a>.</li>
        <li>Orders UI: <code>internalnotes</code> now displayed in list view.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.153-Orders UI.png' alt='Orders UI' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Bulk: Fixed issue with failed media download jobs.</li>
        <li>Automation Engine: Fixed issue with comparing <code>"0.00"</code> and <code>"0"</code> as equal to <code>""</code> when running diffs.</li>
        <li>Reports: Fixed Sales Tax Reporting filtering by <code>sku</code>.</li>
        <li>UI: Fixed issue with sub account editors not loading.</li>
        <li>Orders API: Fixed issue with <code>itemdetails</code> being reset when updating multiple order item user fields.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.152',
    date: 'July 23, 2021',
    title: 'Automations + UI upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Automation Engine: Support <code>field_run</code> placeholders for item fields via <code>placefolders_items placefolders_shipments placefolders_refunds</code>.</li>
        <li>UI: Custom fields now can be managed at <a href='https://app.suredone.com/settings/customfields' target='_blank' rel='noopener noreferrer'>/settings/customfields</a>.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.152-custom-fields.png' alt='Custom Fields UI' />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.152-add-custom-fields.png' alt='Add Custom Fields' />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.152-Edit-Order-Fields.png' alt='Edit Order Fields' />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.152-Edit-Product-Testing.png' alt='Edit Product Testing' />
        </li>
        <li>Templates: introduce function <code>sd_getproducts(query)</code> which accepts a search and returns all product fields.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Bulk: Fixed issue with importing images with invalid SSL certificates.</li>
        <li>Skuvault: Fixed issue with sending empty <code>Statuses</code>.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.151',
    date: 'July 16, 2021',
    title: 'Automations + eBay upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Automation Engine: Enabled update of shipments with fields such as <code>shipcost</code> for existing shipments.</li>
        <li>eBay: Import order <code>shippingphone</code> from buyer <code>ShippingAddress</code> for GSP orders.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.151-ebay.png' alt='eBay Import' />
        </li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.150',
    date: 'July 9, 2021',
    title: 'Automations upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Automations: Setup <code>linked_parameters</code> to improve drop shipping vendor performance.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.149',
    date: 'July 2, 2021',
    title: 'Reporting + UI + Bulk + Channel upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Reporting: Launched accounting, inventory, purchasing and sales reports.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.149-Reports.png' alt='Reports' />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.149-Reports-UI.png' alt='Reports UI' />
        </li>
        <li>UI: Storefront pages and category navigation links are shown is storefront is enabled.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.149-Storefront.png' alt='Storefront' />
        </li>
        <li>UI: Remove amazon asin when using <code>create similar</code>.</li>
        <li>Bulk: Added support for year ranges in bulk fitment files to improve matching query.</li>
        <li>eBay: Changed Shopping API endpoint to use OAuth, as they are deprecating the legacy. If you can no longer use the fitment search functionality in the product interface, please re-authorize your eBay integration.</li>
        <li>Amazon: support for compliance fields as specified in the <a href='https://images-na.ssl-images-amazon.com/images/G/01/rainier/help/xsd/release_1_9/Product._TTH_.xsd' target='_blank' rel='noopener noreferrer'>XSD</a>.
          <ul>
            <li>support for <code>MeltingTemperature</code> valid for US and EU marketplaces.</li>
            <li>support for <code>MinimumOrderQuantity</code> and <code>LiquidateRemainder</code> valid for EU marketplaces.</li>
            <li>support for <code>IsHeatSensitive</code>  valid for US, CA and EU marketplaces.</li>
          </ul>
        </li>
        <li>Facebook: Improved product imports. </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Amazon: Fix issue with field mapping for new compliance fields not saving.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.149-Amazon-Compliance.png' alt='Amazon Compliance' />
        </li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.148',
    date: 'June 25, 2021',
    title: 'Channel + Orders UI upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Google: Migrated Shopping Products API from <code>v2.0</code> to <code>v2.1</code>.</li>
        <li>Shopify: Always send stock values to Shopify when they exist in requests.</li>
        <li>eBay:
          <ul>
            <li>Introduced support for <code>ebayvatpercent</code> to comply with <a href='https://sellercentre.ebay.co.uk/global-sales/2021-vat-changes' target='_blank' rel='noopener noreferrer'>2021 VAT changes</a>.
              <br />
              <img src='https://assets.suredone.com/multichannel-platform/updates/v3.148-Ebay-VAT.png' alt='eBay VAT' />
            </li>
            <li>Imports now include <code>eBayvatpercent</code>.</li>
          </ul>
        </li>
        <li>Orders UI: Enabled marketplace specific <code>currency</code> to be displayed per order.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.148-Orders-UI-iframe.png' alt='Orders UI' />
        </li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.147',
    date: 'June 18, 2021',
    title: 'UI + Bulk upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>UI: Introduced statuses module into the product and order editors. In this version of the release the statuses shown may be delayed by 5 minutes. We are working to improve this performance and provide a means to help you understand what is the most current status for every aspect of product listings and orders.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.147-status-summary-iframe.png' alt='Status Summary Iframe' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Bulk: Fixed issue with edge case media uploads timing out and preventing process completion.</li>
        <li>Bulk: Fixed fitment uploads with no data stuck processing.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.146',
    date: 'June 11, 2021',
    title: 'Automation Engine + Logs UI + ShipStation + Channel upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Automation Engine:
          <ul>
            <li> Linked Parameters: Introduced <code>linked_parameters</code> which uses <code>value_map</code> to assign a set of <tt>paired</tt> values based on the value of the parent parameter. Also allows for all other functionality of a regular parameter (e.g. <code>encrypted</code>, <code>date_format</code>, etc.)</li>
            <li> POST Pagination: Allows for paginated calls to be made via POST both in <code>template</code> and in <code>connection.payload</code> using <code>[PAGE_NUM]</code>.</li>
            <li> Round functions: Introduced <code>round</code> functions to <code>vendor_actions</code>.</li>
          </ul>
        </li>
        <li>Logs UI: Introduced specific page for log details.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.146-Single-Log-UI.png' alt='Single Logs UI' />
        </li>
        <li>ShipStation: Introduced automation to import shipments and <code>shipcost</code> via the <a href='https://www.shipstation.com/docs/api/shipments/list/' target='_blank' rel='noopener noreferrer'><code>List Shipments</code> API</a>.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>BigCommerce: Fixed issue that is sending empty prices as <code>0.00</code>.</li>
        <li>Amazon: Fixed issue to accommodate FBA order item quantity from import multiple line items for same order item.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.145',
    date: 'June 4, 2021',
    title: 'Bulk + UI + Reporting + Channel upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Bulk: Retry updates for edge case missing processed chunks.</li>
        <li>UI: Loading text was added.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.145-Loader-text.png' alt='Loader Text' />
        </li>
        <li>Reporting: Launched Reporta UI for Beta testing. Reach out to SureDone support for early access.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.145-Reports-Beta-UI.png' alt='Reports Beta UI' />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.145-Reports-Beta-UI-2.png' alt='Reports Beta UI Tables' />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.145-Reports-Beta-UI-3.png' alt='Reports Beta UI Graphs' />
        </li>
        <li>Channels: Add support to change channel labels for all channel instances.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.145-Channel-labels.png' alt='Channel label' />
        </li>
        <li>BigCommerce: filter <code>price, sale_price, retail_price, cost_price</code> to prevent processing errors.</li>
        <li>eBay: Import final value fees and payment processing fees into order <code>details.payments</code> field on incomplete orders for sellers with managed payments enabled.</li>
        <li>eBay: UI Imports that are loaded into the account will now import images.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.145-Import-Media.png' alt='Import Media' />
        </li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.144',
    date: 'May 28, 2021',
    title: 'Channel upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Amazon:
          <ul>
            <li> Support for compliance field <code>PesticideMarkingType</code>, accepts either <code> epa_registration_number</code> or <code>epa_establishment_number</code>.</li>
            <li> Support for compliance field <code> PesticideMarkingRegistrationStatus</code>, accepts either <code> fifra_registration_required</code>, <code>fifra_registration_exempt</code> or <code>fifra_not_considered_pesticide</code>.</li>
            <li> Support for compliance field <code>PesticideMarkingCertificationNumber</code> as specified in the <a href='https://images-na.ssl-images-amazon.com/images/G/01/rainier/help/xsd/release_1_9/Product._TTH_.xsd' target='_blank' rel='noopener noreferrer'>XSD</a>.
              <br />
              <img src='https://assets.suredone.com/multichannel-platform/updates/v3.144-Amazon-Compliance.png' alt='Amazon Compliance' />
              <br />
              <img src='https://assets.suredone.com/multichannel-platform/updates/v3.144-Amazon-Compliance-2.png' alt='Amazon Compliance 2' />
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.143',
    date: 'May 21, 2021',
    title: 'Channel + Logs UI upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>MagentoTwo: Custom field mapping now maps <code>ImageName</code>(Image ALT TEXT) to <code>SD.title</code> instead of the filename of the image.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.143-Magneto-Two.png' alt='MagnetoTwo' />
        </li>
        <li>Logs UI: Now when you filter by channel the instance is also added to the filters.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.143-Logs-UI-channel-instance.png' alt='Logs UI' />
        </li>
        <li>Shopify: Channel Authorization from Shopify enabled without having to add a channel in the suredone app first.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Logs UI: Fixed UI bug with channels flowing out of frame on Firefox.</li>
        <li>BigCommerce: Fixed issues with authorizing first instance from BigCommerce after installing the suredone app.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.142',
    date: 'May 14, 2021',
    title: 'Channel + API + UI upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Shopify: Import description to <code>description_html</code> instead of <code>body_html</code>.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.142-Shopify-Field-Mapping.png' alt='Shopify Field Mapping' />
        </li>
        <li>Channel Imports: Upgrade to use new bulk status which shows the progress of loaded files.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.142-Import-Redesign.png' alt='Import Redesign' />
        </li>
        <li>API: Bulk file requests now return a <code>job_id</code> key.</li>
        <li>eBay: Import requests for over 100K products will now use large merchant services.</li>
        <li>UI: Introduced pop up interface to report errors.</li>
        <li>UI Automations: Link from automation log to new log viewer.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.141',
    date: 'May 7, 2021',
    title: 'Channel + Bulk + UI upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>UI: Relaunch product tour buttons were added through out the interface.</li>
        <li>BigCommerce/Shopify: Upon authorization, settings are automatically imported instead of having to subsequently refresh them through the settings page.</li>
        <li>Bulk: performance improvements.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.140',
    date: 'April 30, 2021',
    title: 'Automation Engine + UI upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Automation Engine: Introduced <code>triggers.key_regex</code> to parse headers from trigger requests.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>UI: Fixed issue with orders saved search deletion not working.</li>
        <li>Shopify: Fixed issue wherein some saved <code>image IDs</code> were preventing items from being listed.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.139',
    date: 'April 23, 2021',
    title: 'Bulk + Channel upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Bulk: Internal performance improved.</li>
        <li>eBay: Token authorization automatically triggers OAuth request.</li>
        <li>Shopify: Introduced more logging, including <code>request</code> and <code>response</code>.</li>
        <li>Shopify/BigCommerce: <code>store_url</code> is now editable.</li>
        <li>Skuvault: Supports <code>SupplierInfo</code> - All subfields supported. There can be multiple suppliers, each with its own set of info, on a single product. To support this, user may enter comma-delimited list of fields in each <code>skuvaultsupplierinfo[subfield]</code> field, taking care to ensure the order matches.</li>
        <li>Skuvault: Supports <code>Statuses</code> as comma-delimited list.</li>
        <li>Skuvault: Supports <code>Attributes</code> as a JSON string of arbitrary key/value pairs.</li>
        <li>Skuvault: Full UI, Bulk, and Import support of all of the above.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.139-Skuvault-edit-product.png' alt='Skuvault upgrades' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Shopify/BigCommerce: Fixed issue with in-app authorization and show link to open in new tab.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.139-bigcommerce-Auth.png' alt='Bigcommerce Auth' />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.139-bigcommerce-Link.png' alt='Bigcommerce Link' />
        </li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.138',
    date: 'April 16, 2021',
    title: 'UI + Bulk + Orders upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>UI Logs: Now sorted as descending by default (newest logs on top).</li>
        <li>UI Logs: Filter <code>!=</code> (not equal) was added.</li>
        <li>UI Logs: All columns are sortable now.</li>
        <li>Bulk: Stability improvements introducing retries for failed chunks.</li>
        <li>Orders: V3 API parameter <code>force_json</code> introduced to force json to decode all JSON-encoded fields in the response payload.</li>
        <li>BigCommerce: listing updates with only inventory data will now only modify <code>inventory_level</code>.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>UI Products: Fixed issue with exports generating long file names, now limited to 150 characters.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.137',
    date: 'April 9, 2021',
    title: 'Bulk + Channel upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Bulk: Refactor media processing.</li>
        <li>Walmart: Lagtime feed processing reduced to 6x per day per <a href='https://developer.walmart.com/documentation/throttling/' target='_blank' rel='noopener noreferrer'>Throttling limits</a>.</li>
        <li>Amazon: Product support for <code>OfferingEndDate</code> and <code>OfferingStartDate</code>.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.137-Amzn-Offer.png' alt='Amazon OfferingStartDate OfferingEndDate' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Magento2: Fixed issue with some categories not showing in interface.</li>
        <li>Walmart: Fixed issue with variation inventory/price feed errors on item creation.</li>
        <li>Walmart: Fixed issue with updating products not saving properties that are not listed or pending.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.136',
    date: 'April 2, 2021',
    title: 'Channel upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Walmart: Improved status syncing using bulk.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Storefront: Fixed issue with checkout not completing when creating a new account.</li>
        <li>Amazon: Fixed issue with <code>AutoAccessory.FitType</code> not sending correctly.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.136-Amazon-Fit-Type.png' alt='Amazon Fit Type' />
        </li>
        <li>UI Automations: Fixed issue with shared integration logs not showing.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.136-UI-Automations.png' alt='UI Automations' />
        </li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.135',
    date: 'March 26, 2021',
    title: 'Automation Engine + UI Logs upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Automation Engine: Introduced <code>jsonEncode, base64Encode, base64Decode</code> functions to <code>vendor_actions</code> and <code>suredone_actions</code>.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.135-JSON-Encoded-Test-Config.png' alt='JSON Encoded test config' />
        </li>
        <li>Automation Engine: Introduced <code>base64_encoded</code> to parameter attributes to base64 encode parameter values.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.135-Base64-Test-Config.png' alt='Base64 Test Config' />
        </li>
        <li>Logs UI: Introduced new interface for surfacing system statuses and errors.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.135-Log-viewer.png' alt='Log Viewer' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Walmart: Fixed issue with variations not setting correctly.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.134',
    date: 'March 19, 2021',
    title: 'UI/API + Channel +  Storefront upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>UI Products List: Introduced results per page.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.134-UI-products-orders-results-limit.png' alt='UI Results limit' />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.134-UI-products-orders-results-limit-2.png' alt='UI Results limit 2' />
        </li>
        <li>API: Introduced <code>limit</code> parameter to allow results to return 50, 100, 150, 200, 250 or 500 results per page.</li>
        <li>Media: updates now create hash file names as <code>[identifier]-<tt>hash</tt>-[number].[file extension]</code> to prevent collisions with CDN caching.</li>
        <li>User Fields: Introduced <code>values</code> to set select values for both products & orders.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.134-Product-order-fields.png' alt='User Fields' />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.134-Product-order-fields-2.png' alt='User Fields 2' />
        </li>
        <li>Amazon: Introduced multi ASIN alias support. Entering in multiple delimited ASIN values into the <code>amznasin</code> field will attempt to create multiple alias SKUs on Amazon. <a href='https://support.suredone.com/support/solutions/articles/1000298348-how-to-use-asin-aliases' target='_blank' rel='noopener noreferrer'>Learn more</a>.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.134-Amazon-ASIN.png' alt='Amazon ASIN' />
        </li>
        <li>BigCommerce: Introduced <code>is_condition_shown</code> to product properties editor.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.134-BC-Upgrades.png' alt='BC Upgrades' />
        </li>
        <li>BigCommerce: Improved responses and and error logging.</li>
        <li>BigCommerce: Refreshed on create product retries. If a product encounters BigCommerce API throttle limits or other retryable error during listing creation, the system will now return success to save listing data and also enqueue a <code>relist</code> in attempt to refresh failed attributes.</li>
        <li>BigCommerce: Introduced support for image custom field mapping.</li>
        <li>eBay: remove <code>itemidclear</code> setting.</li>
        <li>eBay: Leveraged notifications to remove orphan listing <code>ItemID</code> from products.</li>
        <li>eBay: Improved auth flow to require setup of necessary settings.</li>
        <li>eBay: Added 3rd step to eBay auth only if the organization is left of country or postalCode fields.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.134-eBay-Onboard.png' alt='eBay Onboard' />
        </li>
        <li>Walmart: Introduced support for <a href='https://support.suredone.com/support/solutions/articles/1000298292-how-to-use-shipping-templates' target='_blank' rel='noopener noreferrer'>shipping templates</a> and update taxonomy to version <code>3.2</code>.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.134-Walmart-item-feed.png' alt='Walmart Items Feed' />
        </li>
        <li>Storefront: Enabled checkout with with <tt>freight method</tt> if service selected is <code>PICKUP</code>.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>BigCommerce: Fixed issue with auth from BigCommerce app store requiring re-authorization.</li>
        <li>eBay: fix issue with default template media gallery.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.133',
    date: 'March 12, 2021',
    title: 'Bulk + Channel upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Walmart: Refactored logic for better processing.</li>
        <li>Bulk: Internal improvements and edge case handling.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Storefront: Fixed issue with <code>PICKUP</code> not saving for fixed shipping mode.</li>
        <li>Etsy: Fixed issue with <code>description</code> not defaulting to <code>longdescription</code>.</li>
        <li>Walmart: Fixed issue with stale data being sent from queue.</li>
        <li>UI Imports: Fix issue with imports not loading and showing errors.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.133-UI-Import-Fix.png' alt='UI Immport fix' />
        </li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.132',
    date: 'March 5, 2021',
    title: 'Channel + Bulk + Dashboard upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>eBay: Updated API version to <code>1193</code>.</li>
        <li>Channels: Updated order imports & tracking updates to 4x per hour.</li>
        <li>UI Products List: Introduce <tt>Export Selected</tt> to export selected products. Exports from here will now name files by search.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.132-products-export-selected.png' alt='Products export selected' />
        </li>
        <li>UI Dashboard: Introduce shipment cost graphs.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.132-dashboard-shipcost.png' alt='Dashboard ship costs' />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.132-dashboard-graphs.png' alt='Dashboard graphs' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Bulk: Fixed issue with edge case of some media import jobs not completing.</li>
        <li>eBay: Fixed issue with broken settings interface, shows errors instead.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.132-eBay-template-bug.png' alt='eBay Template Bug' />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.132-eBay-template-bug-2.png' alt='eBay Template Bug 2' />
        </li>
        <li>eBay: Fixed issue with template names with spaces not uploading correctly.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.132-eBay-loading.png' alt='eBay Loading' />
        </li>
        <li>eBay: Fixed issue with template uploads saving incorrectly.</li>
        <li>Walmart: Fix issue with empty xml.</li>
        <li>Etsy: Fixed issue relisting products that are in the legacy state of inactive <code>edit</code>.</li>
        <li>UI Order Editor: Fix issue with order items not importing <code>media</code>.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.132-orders-media.png' alt='Orders media imported' />
        </li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.131',
    date: 'February 26, 2021',
    title: 'Automation Engine + Channel upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Automation Engine: Improved logic on sending notification email.</li>
        <li>Automation Engine: Added <code>convertDateTime</code> <tt>action</tt> to convert between <tt>DateTime</tt> formats.</li>
        <li>Shopify: Stored <tt>collections</tt> to the item in the database as their text names, not IDs. If a collection does not have an ID, do a dynamic refresh of collections during the item update rather than requiring manual user intervention.</li>
        <li>Amazon: Implemented <code>CarrierCode</code> for submitting order ship tracking. If <code>shipcarrier</code> is a value other than one of <code>4PX, A-1, AAA Cooper, ABF, Asendia, Best Buy, Blue Package, Canada Post, CEVA, China Post, Conway, DHL, DHL eCommerce, Estes, FedEx, Fedex Freight, FedEx SmartPost, First Mile, Hongkong Post, Hunter Logistics, India Post, JCEX, Lasership, Newgistics, Old Dominion, OnTrac, OSM, Pilot Freight, R+L, Roadrunner, Royal Mail, Saia, SF Express, SFC, StreamLite, UPS, UPS Freight, UPS Mail Innovations, Urban Express, USPS, XPO Freight, Yanwen, Yellow Freight, Yun Express, Other</code> then <code>Other</code> will be sent and <code>CarrierName</code> will be sent as the value of <code>shipcarrier</code>.</li>
        <li>Bulk: Upgraded system to improve performance and stability.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Shopify: Fixed issue wherein the user cannot set a <code>Sale Price</code> (compare at price) and then clear it out again afterwards.</li>
        <li>Automation Engine: Fixed issue with missing support for <code>email</code> field in order imports.</li>
        <li>Amazon: Fixed issue with tracking logs saving the identifier as <code>amzn[orderNumber]</code>.</li>
        <li>Amazon: Fixed issue with <code>BusinessPrice</code> not sending correctly.</li>
        <li>Amazon: Fixed issue with <code>AutoAccessory.FitType</code> not sending correctly.</li>
        <li>Etsy: Fixed issue with inventory updates failing some accounts.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.130',
    date: 'February 19, 2021',
    title: 'UI + Storefront Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Usage: Disabled automations on free plan.</li>
        <li>Storefront: Default storefronts now powered by <code>username.mysuredone.com</code>.</li>
        <li>Assets: All new users media and assets now hosted on <code>assets.mysuredone.com</code>.</li>
        <li>Automation Engine: Email notifications are now available on live runs.</li>
        <li>UI: Enabled optional MFA authentication for all users.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.130-Optional-MFA.png' alt='Optional MFA' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Shopify: Fixed issue where product with images cannot be created off of create similar which copies the image IDs over to new product.</li>
        <li>Shopify: Fixed issue with duplicate images being created on variations.</li>
        <li>Shopify: Fixed issue with <code>tags</code> not updating in bulk.</li>
        <li>Shopify: Fixed issue with collections being mistakenly removed via bulk <code>relist</code>.</li>
        <li>Shopify: Fixed issue with collections not able to be added via bulk.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.129',
    date: 'February 12, 2021',
    title: 'UI + bulk Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Bulk: performance & results status upgrades.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.129-Bulk-Results-Editor.png' alt='Bulk Results Editor' />
        </li>
        <li>Billing UI: Default credit card may now be set in <a href='https://app.suredone.com/settings/billing/payment'>Billing &gt; Payments</a>.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.129-Default-Credit-Card.png' alt='Default Credit Card' />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.129-Default-credit-card-2.png' alt='Default Credit Card 2' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Shipping: Fixed issue with shipments created by <tt>Stamps.com</tt> not saving <code>shipcarrier</code> as <tt>usps</tt>.</li>
        <li>Shipping UI: Fixed issue with Stamps.com labels not showing up in the shipping interface.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.129-Shipping-fix.png' alt='Shipping fix' />
        </li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.128',
    date: 'February 5, 2021',
    title: 'DCi Upgrade',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>DCi: Improved diff checking.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.127',
    date: 'January 29, 2021',
    title: 'UI + eBay Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Orders UI: Now display order <code>comments</code> if they are not empty.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.127-Orders-UI.png' alt='Orders UI' />
        </li>
        <li>eBay: Removed listings <code>ShippingSurcharge</code>.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.127-eBay-Shipping-surcharge.png' alt='eBay Shipping Surcharge' />
        </li>
        <li>Bulk: Launched new processor - improves reliability, performance and results viewer.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.127-Bulk-New-Processor.png' alt='Bulk New Processor' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>eBay: Fixed issue for error returning for unauthorized integrations.</li>
        <li>UI: Fixed issue with product editor <code>alwaysSend</code> default skip not displaying.</li>
        <li>UI: Fixed issue with <tt>Limits exceeded</tt> errors not displaying.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.126',
    date: 'January 22, 2021',
    title: 'Facebook Upgrade',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Facebook: upgrade to Graph API v9.0</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.125',
    date: 'January 15, 2021',
    title: 'UI + Bigcommerce Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>BigCommerce: Support order import <code>total</code> and <code>payment</code> for <code>store_credit_amount</code> and <code>gift_certificate_amount</code>.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.125-BigCommerce-Orders-Support.png' alt='BigCommerce Oders Support' />
        </li>
        <li>UI: Channel is now added when you click <code>Add channel</code> button on the available channels list.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.125-Add-Channel-on-First-Click.png' alt='Add Channel on First Click' />
        </li>
        <li>UI: Channel import progress improved.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.125-Channel-Import-Progress.png' alt='Channel Import Progress' />
        </li>
        <li>UI: Permission system upgrades.</li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>Shopify: Fix issue with <code>compare_at_price</code> failing for mapped empty fields.</li>
        <li>Shopify: Fix issue with duplicate images being sent.</li>
      </ul>
    </li>
  </ul>
  },
  {
    id: 'v3.124',
    date: 'January 8, 2021',
    title: 'UI + Storefront Upgrades',
    content:
  <ul>
    <li><strong>Upgrades</strong>
      <ul>
        <li>Storefront: Introduced support for custom <a href='https://developers.google.com/recaptcha' target='_blank' rel='noopener noreferrer'>reCAPTCHA</a>, see <a href='https://support.suredone.com/support/solutions/articles/1000296514-storefront-contact-form-recaptcha'>guide</a> for more information.
          <br />
          <img src='https://assets.suredone.com/multichannel-platform/updates/v3.124-Storefront-reCAPTCHA.png' alt='Storefront reCAPTCHA' />
        </li>
      </ul>
    </li>
    <li><strong>Fixes</strong>
      <ul>
        <li>UI: Fixed issue with large channel imports not loading in interface.</li>
      </ul>
    </li>
  </ul>
  }
]

const finalInfo = {
  title: '2020 Product Updates',
  link: 'https://www.suredone.com/product-updates/2020/'
}

export { news }
export { finalInfo }
